.spacing-mt-negative10 {
  margin-top: -10px ;
}

.spacing-pt-50 {
  padding-top: 50px !important;
}

.spacing-pr-50 {
  padding-right: 50px !important;
}

.spacing-pb-50 {
  padding-bottom: 50px !important;
}

.spacing-pl-50 {
  padding-left: 50px !important;
}

.spacing-mt-50 {
  margin-top: 50px !important;
}

.spacing-mr-50 {
  margin-right: 50px !important;
}

.spacing-mb-50 {
  margin-bottom: 50px !important;
}

.spacing-ml-50 {
  margin-left: 50px !important;
}

.spacing-pt-45 {
  padding-top: 45px !important;
}

.spacing-pr-45 {
  padding-right: 45px !important;
}

.spacing-pb-45 {
  padding-bottom: 45px !important;
}

.spacing-pl-45 {
  padding-left: 45px !important;
}

.spacing-mt-45 {
  margin-top: 45px !important;
}

.spacing-mr-45 {
  margin-right: 45px !important;
}

.spacing-mb-45 {
  margin-bottom: 45px !important;
}

.spacing-ml-45 {
  margin-left: 45px !important;
}

.spacing-pt-40 {
  padding-top: 40px !important;
}

.spacing-pr-40 {
  padding-right: 40px !important;
}

.spacing-pb-40 {
  padding-bottom: 40px !important;
}

.spacing-pl-40 {
  padding-left: 40px !important;
}

.spacing-mt-40 {
  margin-top: 40px !important;
}

.spacing-mr-40 {
  margin-right: 40px !important;
}

.spacing-mb-40 {
  margin-bottom: 40px !important;
}

.spacing-ml-40 {
  margin-left: 40px !important;
}

.spacing-pt-35 {
  padding-top: 35px !important;
}

.spacing-pr-35 {
  padding-right: 35px !important;
}

.spacing-pb-35 {
  padding-bottom: 35px !important;
}

.spacing-pl-35 {
  padding-left: 35px !important;
}

.spacing-mt-35 {
  margin-top: 35px !important;
}

.spacing-mr-35 {
  margin-right: 35px !important;
}

.spacing-mb-35 {
  margin-bottom: 35px !important;
}

.spacing-ml-35 {
  margin-left: 35px !important;
}

.spacing-pt-30 {
  padding-top: 30px !important;
}

.spacing-pr-30 {
  padding-right: 30px !important;
}

.spacing-pb-30 {
  padding-bottom: 30px !important;
}

.spacing-pl-30 {
  padding-left: 30px !important;
}

.spacing-mt-30 {
  margin-top: 30px !important;
}

.spacing-mr-30 {
  margin-right: 30px !important;
}

.spacing-mb-30 {
  margin-bottom: 30px !important;
}

.spacing-ml-30 {
  margin-left: 30px !important;
}

.spacing-pt-25 {
  padding-top: 25px !important;
}

.spacing-pr-25 {
  padding-right: 25px !important;
}

.spacing-pb-25 {
  padding-bottom: 25px !important;
}

.spacing-pl-25 {
  padding-left: 25px !important;
}

.spacing-mt-25 {
  margin-top: 25px !important;
}

.spacing-mr-25 {
  margin-right: 25px !important;
}

.spacing-mb-25 {
  margin-bottom: 25px !important;
}

.spacing-ml-25 {
  margin-left: 25px !important;
}

.spacing-pt-20 {
  padding-top: 20px !important;
}

.spacing-pr-20 {
  padding-right: 20px !important;
}

.spacing-pb-20 {
  padding-bottom: 20px !important;
}

.spacing-pl-20 {
  padding-left: 20px !important;
}

.spacing-mt-20 {
  margin-top: 20px !important;
}

.spacing-mr-20 {
  margin-right: 20px !important;
}

.spacing-mb-20 {
  margin-bottom: 20px !important;
}

.spacing-ml-20 {
  margin-left: 20px !important;
}

.spacing-pt-15 {
  padding-top: 15px !important;
}

.spacing-pr-15 {
  padding-right: 15px !important;
}

.spacing-pb-15 {
  padding-bottom: 15px !important;
}

.spacing-pl-15 {
  padding-left: 15px !important;
}

.spacing-mt-15 {
  margin-top: 15px !important;
}

.spacing-mr-15 {
  margin-right: 15px !important;
}

.spacing-mb-15 {
  margin-bottom: 15px !important;
}

.spacing-ml-15 {
  margin-left: 15px !important;
}

.spacing-pt-10 {
  padding-top: 10px !important;
}

.spacing-pr-10 {
  padding-right: 10px !important;
}

.spacing-pb-10 {
  padding-bottom: 10px !important;
}

.spacing-pl-10 {
  padding-left: 10px !important;
}

.spacing-mt-10 {
  margin-top: 10px !important;
}

.spacing-mr-10 {
  margin-right: 10px !important;
}

.spacing-mb-10 {
  margin-bottom: 10px !important;
}

.spacing-ml-10 {
  margin-left: 10px !important;
}

.spacing-pt-5 {
  padding-top: 5px !important;
}

.spacing-pr-5 {
  padding-right: 5px !important;
}

.spacing-pb-5 {
  padding-bottom: 5px !important;
}

.spacing-pl-5 {
  padding-left: 5px !important;
}

.spacing-mt-5 {
  margin-top: 5px !important;
}

.spacing-mr-5 {
  margin-right: 5px !important;
}

.spacing-mb-5 {
  margin-bottom: 5px !important;
}

.spacing-ml-5 {
  margin-left: 5px !important;
}

.spacing-pt-0 {
  padding-top: 0 !important;
}

.spacing-pr-0 {
  padding-right: 0 !important;
}

.spacing-pb-0 {
  padding-bottom: 0 !important;
}

.spacing-pl-0 {
  padding-left: 0 !important;
}

.spacing-mt-0 {
  margin-top: 0 !important;
}

.spacing-mr-0 {
  margin-right: 0 !important;
}

.spacing-mb-0 {
  margin-bottom: 0 !important;
}

.spacing-ml-0 {
  margin-left: 0 !important;
}

.spacing-p-50 {
  padding: 50px !important;
}

.spacing-p-45 {
  padding: 45px !important;
}

.spacing-p-40 {
  padding: 40px !important;
}

.spacing-p-35 {
  padding: 35px !important;
}

.spacing-p-30 {
  padding: 30px !important;
}

.spacing-p-25 {
  padding: 25px !important;
}

.spacing-p-20 {
  padding: 20px !important;
}

.spacing-p-15 {
  padding: 15px !important;
}

.spacing-p-10 {
  padding: 10px !important;
}

.spacing-p-5 {
  padding: 5px !important;
}

.spacing-m-50 {
  margin: 50px !important;
}

.spacing-m-45 {
  margin: 45px !important;
}

.spacing-m-40 {
  margin: 40px !important;
}

.spacing-m-35 {
  margin: 35px !important;
}

.spacing-m-30 {
  margin: 30px !important;
}

.spacing-m-25 {
  margin: 25px !important;
}

.spacing-m-20 {
  margin: 20px !important;
}

.spacing-m-15 {
  margin: 15px !important;
}

.spacing-m-10 {
  margin: 10px !important;
}

.spacing-m-5 {
  margin: 5px !important;
}

@media screen and (min-width: 768px) {
  .spacing-md-mt-60 {
    margin-top: 60px !important;
  }

  .spacing-md-mt-50 {
    margin-top: 50px !important;
  }

  .spacing-md-mt-45 {
    margin-top: 45px !important;
  }

  .spacing-md-mt-40 {
    margin-top: 40px !important;
  }

  .spacing-md-mt-35 {
    margin-top: 35px !important;
  }

  .spacing-md-mt-30 {
    margin-top: 30px !important;
  }

  .spacing-md-mt-25 {
    margin-top: 25px !important;
  }

  .spacing-md-mt-20 {
    margin-top: 20px !important;
  }

  .spacing-md-mb-60 {
    margin-bottom: 60px !important;
  }

  .spacing-md-mb-50 {
    margin-bottom: 50px !important;
  }

  .spacing-md-mb-45 {
    margin-bottom: 45px !important;
  }

  .spacing-md-mb-40 {
    margin-bottom: 40px !important;
  }

  .spacing-md-mb-35 {
    margin-bottom: 35px !important;
  }

  .spacing-md-mb-30 {
    margin-bottom: 30px !important;
  }

  .spacing-md-mb-25 {
    margin-bottom: 25px;
  }

  .spacing-md-mb-20 {
    margin-bottom: 20px !important;
  }

  .spacing-md-pt-60 {
    padding-top: 60px;
  }

  .spacing-md-pt-55 {
    padding-top: 55px;
  }

  .spacing-md-pt-50 {
    padding-top: 50px;
  }

  .spacing-md-pt-45 {
    padding-top: 45px;
  }

  .spacing-md-pt-40 {
    padding-top: 40px;
  }

  .spacing-md-pt-35 {
    padding-top: 35px;
  }

  .spacing-md-pt-30 {
    padding-top: 30px;
  }

  .spacing-md-pt-25 {
    padding-top: 25px;
  }

  .spacing-md-pt-20 {
    padding-top: 20px;
  }

  .spacing-md-pt-15 {
    padding-top: 15px;
  }

  .spacing-md-pt-10 {
    padding-top: 10px;
  }

  .spacing-md-pt-5 {
    padding-top: 5px;
  }

  .spacing-md-pt-0 {
    padding-top: 0px;
  }

  .spacing-md-pb-5 {
    padding-bottom: 5px;
  }

  .spacing-md-pb-10 {
    padding-bottom: 10px;
  }

  .spacing-md-pb-15 {
    padding-bottom: 15px;
  }

  .spacing-md-pb-20 {
    padding-bottom: 20px;
  }

  .spacing-md-pb-25 {
    padding-bottom: 25px;
  }

  .spacing-md-pb-30 {
    padding-bottom: 30px;
  }

  .spacing-md-pb-35 {
    padding-bottom: 35px;
  }

  .spacing-md-pb-40 {
    padding-bottom: 40px;
  }

  .spacing-md-pb-45 {
    padding-bottom:45px;
  }

  .spacing-md-pb-50 {
    padding-bottom: 50px;
  }

  .spacing-md-pb-55 {
    padding-bottom:55px;
  }

  .spacing-md-pb-60 {
    padding-bottom: 60px;
  }

  .spacing-md-pl-0 {
    padding-left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .spacing-tablet-pt-5 {
    padding-top: 5px;
  }

  .spacing-tablet-pt-10 {
    padding-top: 10px;
  }

  .spacing-tablet-pt-15 {
    padding-top: 15px;
  }

  .spacing-tablet-pt-20 {
    padding-top: 20px;
  }

  .spacing-tablet-pt-25 {
    padding-top: 25px;
  }

  .spacing-tablet-pt-30 {
    padding-top: 30px;
  }

  .spacing-tablet-pt-35 {
    padding-top: 35px;
  }

  .spacing-tablet-pt-40 {
    padding-top: 40px;
  }

  .spacing-tablet-mt-5 {
    margin-top: 5px;
  }

  .spacing-tablet-mt-10 {
    margin-top: 10px;
  }

  .spacing-tablet-mt-15 {
    margin-top: 15px;
  }

  .spacing-tablet-mt-20 {
    margin-top: 20px;
  }

  .spacing-tablet-mt-25 {
    margin-top: 25px;
  }

  .spacing-tablet-mt-30 {
    margin-top: 30px;
  }

  .spacing-tablet-mt-35 {
    margin-top: 35px;
  }

  .spacing-tablet-mt-40 {
    margin-top: 40px;
  }

  .spacing-tablet-mt-45 {
    margin-top: 45px;
  }

  .spacing-tablet-mt-50 {
    margin-top: 50px;
  }

  .spacing-tablet-mt-55 {
    margin-top: 55px;
  }

  .spacing-tablet-mt-60 {
    margin-top: 60px;
  }

  .spacing-tablet-pb-5 {
    padding-bottom: 5px;
  }

  .spacing-tablet-pb-10 {
    padding-bottom: 10px;
  }

  .spacing-tablet-pb-15 {
    padding-bottom: 15px;
  }

  .spacing-tablet-pb-20 {
    padding-bottom: 20px;
  }

  .spacing-tablet-pb-25 {
    padding-bottom: 25px;
  }

  .spacing-tablet-pb-30 {
    padding-bottom: 30px;
  }

  .spacing-tablet-pb-35 {
    padding-bottom: 35px;
  }

  .spacing-tablet-pb-40 {
    padding-bottom: 40px;
  }
  
}

@media screen and (max-width: 767px) {
  .spacing-xs-mt-5 {
    margin-top: 5px !important;
  }

  .spacing-xs-mt-10 {
    margin-top: 10px !important;
  }

  .spacing-xs-mt-15 {
    margin-top: 15px !important;
  }

  .spacing-xs-mt-20 {
    margin-top: 20px !important;
  }

  .spacing-xs-mt-25 {
    margin-top: 25px !important;
  }

  .spacing-xs-mt-30 {
    margin-top: 30px !important;
  }

  .spacing-xs-mt-35 {
    margin-top: 35px !important;
  }

  .spacing-xs-mt-40 {
    margin-top: 40px !important;
  }

  .spacing-xs-mt-45 {
    margin-top: 45px !important;
  }

  .spacing-xs-mt-50 {
    margin-top: 50px !important;
  }

  .spacing-xs-mt-55 {
    margin-top: 55px !important;
  }

  .spacing-xs-mt-60 {
    margin-top: 60px !important;
  }

  .spacing-xs-mb-5 {
    margin-bottom: 5px !important;
  }

  .spacing-xs-mb-10 {
    margin-bottom: 10px !important;
  }

  .spacing-xs-mb-15 {
    margin-bottom: 15px !important;
  }

  .spacing-xs-mb-20 {
    margin-bottom: 20px !important;
  }

  .spacing-xs-mb-25 {
    margin-bottom: 25px !important;
  }

  .spacing-xs-mb-30 {
    margin-bottom: 30px !important;
  }

  .spacing-xs-mb-35 {
    margin-bottom: 35px !important;
  }

  .spacing-xs-mb-40 {
    margin-bottom: 40px !important;
  }

  .spacing-xs-mb-45 {
    margin-bottom: 45px !important;
  }

  .spacing-xs-mb-50 {
    margin-bottom: 50px !important;
  }

  .spacing-xs-mb-55 {
    margin-bottom: 55px !important;
  }

  .spacing-xs-mb-60 {
    margin-bottom: 60px !important;
  }

  .spacing-xs-pl-0 {
    padding-left: 0;
  }

  .spacing-xs-pr-0 {
    padding-right: 0;
  }

  .spacing-xs-pt-5 {
    padding-top: 5px;
  }

  .spacing-xs-pt-10 {
    padding-top: 10px;
  }

  .spacing-xs-pt-15 {
    padding-top: 15px;
  }

  .spacing-xs-pt-20 {
    padding-top: 20px;
  }

  .spacing-xs-pt-25 {
    padding-top: 25px;
  }

  .spacing-xs-pt-30 {
    padding-top: 30px;
  }

  .spacing-xs-pt-35 {
    padding-top: 35px;
  }

  .spacing-xs-pt-40 {
    padding-top: 40px;
  }

  .spacing-xs-pb-5 {
    padding-bottom: 5px;
  }

  .spacing-xs-pb-10 {
    padding-bottom: 10px;
  }

  .spacing-xs-pb-15 {
    padding-bottom: 15px;
  }

  .spacing-xs-pb-20 {
    padding-bottom: 20px;
  }

  .spacing-xs-pb-25 {
    padding-bottom: 25px;
  }

  .spacing-xs-pb-30 {
    padding-bottom: 30px;
  }

  .spacing-xs-pb-35 {
    padding-bottom: 35px;
  }

  .spacing-xs-pb-40 {
    padding-bottom: 40px;
  }

  .spacing-xs-pb-45 {
    padding-bottom: 45px;
  }

  .spacing-xs-pb-50 {
    padding-bottom: 50px;
  }

  .spacing-xs-pb-55 {
    padding-bottom: 55px;
  }

  .spacing-xs-pb-60 {
    padding-bottom: 60px;
  }

  .spacing-xs-pl-0 {
    padding-left: 0;
  }
}