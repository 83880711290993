.plum-uplift {
    .row-header {
        background-color: @white;
    }

    .font-size-huge {
        line-height: 50px;
      }
      
      .font-size-big {
        font-size: @font-size-big !important;
        line-height: 44px;
      }
      
      .font-size-large {
        font-size: @font-size-large !important;
        line-height: 42px;
      }
      
      .font-size-medium {
        font-size: @font-size-medium !important;
        line-height: 36px;
      }
      
      .font-size-regular {
        font-size: @font-size-regular !important;
        line-height: 32px;
      }
      
      .font-size-small {
        font-size: @font-size-small !important;
        line-height: 28px;
      }
      
      .font-size-little {
        font-size: @font-size-little !important;
        line-height: 26px;
      }
      
      .font-size-body {
        font-size: @font-size-body !important;
        line-height: 24px;
      }
      
      .font-size-tiniest {
        font-size: @font-size-tiniest;
        line-height: 22px;
      }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 15px;
        font-family: @font-family-bold;
    }

    .crumbcontainer {
        display: block;
        margin-top: 40px;
    }
      
    .breadcrumb {
        background-color: transparent;
        font-size: @font-size-tiniest;

        li {
            a {
                color: #67787e;
            }
        }

        .active {
            color: #67787e;
            font-weight: 700;
        }
    }
      
    .breadcrumb > li + li::before {
        color: #67787e;
    }

    .body-content-links {
        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .body-content-lists {
        ul, ol {
            padding-left: 15px;

            li {
                padding-bottom: 5px;
            }
        }
    }

    .link-accordion {
        .panel-heading {
            padding-top: 0;
            padding-bottom: 0;
        }

        .panel-title {
            margin-bottom: 0;
        }
    }

    .row-body {
        .fa-chevron-right-regular {
            font-size: @font-size-body !important;
            display: inline-block;
            padding-left: 8px;
        }
    }

    .btn-cta-primary {
        font-size: @font-size-small !important;
    }
}

.border-bottom {
    border-bottom: 2px solid #e2e6e8;
}

.external-link {
    .border-btn-white,
    .border-btn-primary,
    .btn-cta-primary {
        &:after {
            content: "\f35d";
            font-family: "Font Awesome Pro Regular";
            padding-left: 15px;
            display: inline-block;
        }
    }
}

//Hero banner 

.hero-banner {
    background-size: cover !important;
    background-position: center center !important;
}

.hero-banner-content {
    .btn-cta-primary {
        font-size: @font-size-small;
        padding: 10px 30px 12px 30px;
    }
}

.content-pg-hero-banner {
    background-size: cover !important;
    background-position: center top !important;
}

// Quicklinks

.wrapper-quicklinks img {
    height: 65px;
    margin-bottom: 10px;
}

//Overlapped image styles

.overlapped-col-left-bg {
    h2 {
        &:first-of-type {
            font-family: @font-family-light;
            margin-bottom: 20px;
            font-size: @font-size-body !important;
            line-height: 24px;
        }
    }

    .text-corpid-bold * {
        font-family: @font-family-bold !important;
    }

    .fa-chevron-right-regular {
        font-size: @font-size-body;
        padding-left: 3px;
    }
}

.overlapped-col-right-bg {
    h2 {
        &:first-of-type {
            font-family: @font-family-light;
            margin-bottom: 20px;
            font-size: @font-size-body !important;
            line-height: 24px;
        }
    }

    .text-corpid-bold * {
        font-family: @font-family-bold !important;
    }

    .fa-chevron-right-regular {
        font-size: @font-size-body;
        padding-left: 3px;
    }
}

//Primary promo card

.wrapper-promo-primary {
    background-size: cover !important;
    background-position: centre top !important;
}

.promo-primary-content {
    background-color: @brand-primary;
    color: @white;

    a {
        color: @white;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

//Primary CTA
.wrapper-primary-cta {
    color: @white;
    background-color: @brand-primary;

    p {
        color: @white;
    }
}


//Info cards

.info-card-img {
    box-shadow: 0 -8px 6px -6px rgba(163,173,178,0.4);
    padding-top: 30px;
    padding-bottom: 10px;

    img {
        height: 50px;
    }
}

.info-card-link {
    box-shadow: 0 8px 6px -6px rgba(163,173,178,0.4);
    padding-bottom: 50px;
    padding-top: 20px;

    p {
        font-size: @font-size-small;
        font-family: @font-family-bold;
    }

    a:after {
        content: "\f054";
        font-family: "Font Awesome Pro Solid";
        width: auto;
        padding-left: 10px;
        font-size: @font-size-tiniest;
    }
}

//Content card with #f2f4f6 background

.content-card {
    background-color: @soft-grey;
    padding: 40px 30px 20px 30px;
    min-height: 280px;
}

//Product promo homepage

.wrapper-product-promo {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

//Super page

.wrapper-product-cta {
    color: @white;

    p, h2, h3, h4 {
        color: @white;
    }

    .border-btn-white:after {
        display: inline-block;
        content: "\f35d";
        font-family: "Font Awesome Pro Regular";
        padding-left: 10px;
        width: auto;
    }
}

//Forms and brochures - accordion styles

.updated-accordion {
    .panel {
        margin-top: 0;

        .list-item-text {
            border: 0;
            border-bottom: 1px solid #e2e6e8;
            padding: 20px 10px 20px 0 !important;
            font-size: @font-size-body;
        }
    }

    .panel-heading {
        border-bottom: 1px solid #e2e6e8 !important;
        background-color: @soft-grey !important;
        min-height: 42px;
        padding: 10px 15px 10px 15px;
        margin-bottom: 10px;

        h3 {
            font-size: @font-size-body !important;
            font-family: @font-family-light;
            margin-bottom: 0;
        }

        .panel-title {
            a.collapsed {
                background: none;
                position: relative;

                &:after {
                    content: "\f078";
                    width: auto;
                    font-family: "Font Awesome Pro Regular";
                    font-size: @font-size-body;
                    position: absolute;
                    right: 0;
                    margin-right: 0;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        a {
            background: none;
            position: relative;

            &:after {
                content: "\f077";
                width: auto;
                font-family: "Font Awesome Pro Regular";
                font-size: @font-size-body;
                position: absolute;
                right: 0;
                margin-right: 0;
                display: block;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

//Forms and publications table

.forms-table {
    tr {
        border-bottom: 1px solid #e2e6e8;

        &:last-child {
            border-bottom: 0;
        }
    }

    td {
        padding-top: 20px;
        padding-bottom: 5px;
        vertical-align: top;
    }
}

//Calculator card

.calculator-card {
    box-shadow: 0 3px 10px rgba(163,173,178,0.4);
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    img {
        height: 50px;
    }

    p:last-child {
        padding-top: 10px;

        a {
            font-size: @font-size-small;
            line-height: 28px;
            font-family: @font-family-bold;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .fa-chevron-right-regular {
        font-size: @font-size-body;
        padding-left: 8px;
        display: inline-block;
    }
}

//PHD tabs spacing

.wrapper-phd-tabs {
    .nav-tabs {
        margin-bottom: 60px;
    }
}

//Footer

.row-footer {
    float: none !important;

    .nav-tabs {
        border: medium none;
        float: none;
        margin: 20px 0;

        li {
            a {
                border-radius: 0;
                color: @brand-primary;
                margin-top: 0!important;
                padding:0px 10px !important;
                text-decoration: underline;
                cursor: pointer;

                :hover,
                &:focus {
                    background-color: transparent;
                    text-decoration: none;
                    border: 0;
                }
            }

            &.last a {
                padding-right:0 !important;
                margin-right:0 !important;
                border-right:0 !important;
            }

            &.active a {
                background-color: transparent;
                text-decoration: none;
                border: 0;
            }
        }
    }

    .trust-info {
        color: #67787e;
        font-size: 14px;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #e2e6e8;

        p {
            font-size: 14px;
            line-height: 22px;
            color: #67787e;
        }
    }
}

.wrapper-get-in-touch {
    padding-top: 60px;
    padding-bottom: 50px;

    img {
        height: 70px;
        display: inline-block;
    }

    .media-body {
        position: relative;
        height: 70px;

        .text-image {
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-45%);
        }
    }
}

.wrapper-footer {
    ul {
        padding-left: 0;
        margin-bottom: 30px;

        li {
            display: inline;
            list-style-type: none;
            font-size: 16px;
            padding-right: 20px;

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .wrapper-plum-app-links {
        .plum-app-heading {
            p {
                margin-bottom: 10px;
            }
        }

        img {
            padding-right: 5px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .plum-uplift {
        .container {
            max-width: 1140px;
        }
    }
}

@media screen and (min-width: 768px) {
    .right {
        float: right;
    }

    .left {
        float: left;
    }

    .center {
        text-align: center !important;
    }
    
    .hero-banner {
        height: 300px;
    }

    .hero-banner-content {
        position: relative;
        height: 300px;

        .text-image {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            h1 {
                font-size: @font-size-huge !important;
                line-height: 50px;
                margin-bottom: 15px;
            }

            p {
                font-size: @font-size-regular;
                font-family: @font-family-regular;
                line-height: 32px;
            }
        }
    }
    
    .content-pg-hero-banner-content {
        h1 {
            font-size: 42px !important;
            line-height: 50px;
            margin-top: 60px;
        }
    }

    //Overlapped image styles

    .overlapped-col-left-bg {
        height: 100%;
        background: linear-gradient(90deg, #f2f4f6 70%, #ffffff 0);
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 60px;
    }

    .overlapped-col-right-bg {
        height: 100%;
        background: linear-gradient(90deg, #ffffff 30%, #f2f4f6 70%);
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 60px;
    }

    //Hero banner and breadcrumbs content page

    .content-pg-hero-banner {
        height: 300px;
    }
      
    .content-pg-hero-banner-content {
        font-size: 40px !important;
        height: 300px;
        margin-top: -300px;
    }

    //Calculator card

    .calculator-card {
        min-height: 360px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .plum-uplift {
        .wrapper-get-in-touch {
            br {
                display: inline-block;
                width: auto;
            }
        }
    }

    .no-padding-md {
        padding: 0 !important;
    }

    .width-100 {
        width: 100%;
    }

    .hero-banner-content {
        margin-top: -300px !important;
    }

    .content-pg-hero-banner {
        background-position: 85% top !important;

        &.super {
            background-position: 55% top !important;
        }
    }

    .wrapper-promo-primary {
        height: 300px !important;
    }

    .wrapper-primary-cta {
        .primary-cta-img {
            display: none;
        }
    }
    
    .forms-table {
        td:nth-child(1) {
            width: 55%;
        }
    }

    .wrapper-get-in-touch {
        .call-us-card {
            p {
                width: 70%;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .no-padding-lg {
        padding: 0 !important;
    }

    .hero-banner {
        height: 500px;
    }

    .hero-banner-content {
        height: 500px;
        margin-top: -500px !important;
    }

        // Primary promo card

        .wrapper-promo-primary {
            height: 500px;
            background-size: cover !important;
            background-size: centre top !important;
        }
    
        .promo-primary-content {
            .promo-overlay;
        }

    .wrapper-primary-cta {
        padding-top: 60px;
        color: @white;
        background-color: @brand-primary;
        height: 305px;

        p {
            color: @white;
        }

        .primary-cta-img {
            margin-top: 30px;

            p {
                margin-bottom: 0;
            }

            img {
                height: 215px;
            }

            &.primary-cta-img-super {
                margin-top: -45px;

                img {
                    height: 290px;
                }
            }
        }
    }

    .wrapper-get-in-touch {
        .contact-form-card .media-body,
        .email-us-card .media-body {
            padding-left: 15px;
        }
    }
}

@media screen and (max-width: 991px) {
    .hidden-on-mobile {
        display: none;
    }

    .wrapper-primary-cta {
        padding-top: 30px;
        padding-bottom: 20px;
        color: @white;

        p {
            color: @white;
        }

        .primary-cta-img {
            display: none;
        }
    }

    .wrapper-promo-primary {
        height: 170px;
        background-position: 100% !important;
    }

    .promo-primary-content {
        background-color: @brand-primary;
        color: @white;
        padding-top: 20px;
        padding-bottom: 50px;

        h2, h3, p {
            color: @white;
        }
    }

    .info-card {
        margin-bottom: 40px;
    }

    .info-card-link {
        padding-bottom: 20px;
    }

    //Accordion

    .updated-accordion {
        .panel-heading {
            .panel-title {
                a, a.collapsed {
                    width: 98%;
                    line-height: 24px;
                }
            }
        }
    }

    //PHD tabs spacing

.wrapper-phd-tabs {
    .nav-tabs {
        margin-bottom: 30px;
    }
}

     //Footer

     .wrapper-get-in-touch {
        padding-top: 30px;
        padding-bottom: 30px;

        img {
            height: 60px;
        }

        .media-body {
            height: 60px;
            padding-left: 15px;
        }
    }

    .wrapper-footer {
        ul {
            li {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 767px) {    
    .no-padding-xs {
        padding: 0 !important;
    }

    .center-on-mobile {
        text-align: center;
    }

    .border-top {
        border-top: 1px solid #e2e6e8;
    }
    
    .plum-uplift {
        .btn-cta-primary,
        .border-btn-primary,
        .border-btn-white {
            width: 100%;
        }

        .crumbcontainer {
            margin-top: 30px;

            .breadcrumb {
                padding: 0 0 8px 0;

                li:before {
                    padding: 0 !important;
                }
            }
        }

        .col-custom {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .updated-accordion {
        .panel {
            .list-item-text {
                padding-top: 10px !important;
            }
        }
    }

    .hero-banner {
        height: 220px;
        background-position: 85% top !important;
        background-size: cover !important;
    }

    .hero-banner-content {
        padding-top: 20px;

        h1 {
            font-size: @font-size-large !important;
            line-height: 42px;
        }
    }

    .content-pg-hero-banner {
        height: 220px;
        background-position: 85% top !important;

        &.super {
            background-position: 65% top !important;
        }
    }
      
    .content-pg-hero-banner-content {
        h1 {
            font-size: 34px !important;
            line-height: 42px;
        }
    }

    .wrapper-quicklinks {
        br {
            display: none;
        }
    }

    .wrapper-product-promo {
        padding-left: 10px;
        padding-right: 10px;
    }

    .wrapper-promo-primary {
        height: 220px;
        background-position: 85% top !important;
    }

    //Content card with #f2f4f6 background

    .content-card {
        padding: 40px 15px 20px 30px;
        min-height: 1px;
        height: auto;
    }

    .forms-table {
        td {
            display: block;
            padding: 0;

            &:nth-child(1) {
                padding-top: 20px;
                padding-bottom: 10px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                width: 50%;
                float: left;
                padding-top: 0;
                min-height: 1px;
                line-height: 1px;

                p {
                    padding-bottom: 10px;
                }
            }
        }
    }

    .calculator-card {
        padding-bottom: 20px;
        margin-bottom: 30px;
    }

    .wrapper-get-in-touch {
        .call-us-card .media-body {
            height: 70px;
        }
    }
}