//Custom Mixins

// Bootstrap includes a number of Mixins: see bootstrap-temp/clientlibs/css/mixins.less

  .full-width() {
    padding-right:9999em;
    margin-right:-9999em;
    padding-left:9999em;
    margin-left:-9999em;
  }

  .btn-generic() {
    border-radius: 0px;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 15px;
    min-width: 155px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: #ffffff !important;
  }

  .border-btn-generic(@color, @border-color) {
    padding: 10px 30px 10px 30px;
    height: 50px;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    text-decoration: none;
    font-family: @font-family-bold;
    font-size: @font-size-small;
    width: auto;
    line-height: 24px;
    color: @color;
    border: 2px solid @border-color;
  
    &:hover,
    &:focus {
      color: @color;
      border: 2px solid @border-color;
      text-decoration: underline;
    }
  }


  .promo-overlay {
    width: 540px;
    height: 580px;
    margin-top: -540px;
    padding-top: 90px;
    padding-left: 30px;
    padding-right: 30px;

    p {
      color: @white;
    }
  }
