@import "mixins.less";

//Global styles for retirement hub
.text-brand-color {
  color:@brand-primary;
}
.retirement-hub-intro h2 {
    margin-bottom:20px;
    color:@brand-primary;
  font-size:22px !important;
}

//Banner + semi circle styles

.wrapper-masthead {
    height:243px;
    background-position:100% 0 !important;
    background-size:cover !important;
    margin-right:0;
    margin-left:0;
  margin-top:40px;
}

.wrapper-masthead-heading {
  width:400px;
  height:200px;
  border-radius: 400px 400px 0 0;
  background-color:#63053d;
  opacity:0.8;
  color:#ffffff;
  margin-left:20px;
  position:absolute;
  top:43px;
}

.wrapper-masthead-heading h1 {
    margin-left:auto;
    margin-right:auto;
    width:70%;
    font-size:34px !important;
    text-align:center;
    margin-top:62px;
}

.in-retirement-heading h1,
.focused-heading h1 {
  width:81%;
  margin-top:85px;
}

.wrapper-masthead-heading p {
  text-align:center;
    font-size:18px;
    width:99%;
    margin-left:auto;
    margin-right:auto;
}

//Secondary navigation

.wrapper-retirement-hub-nav {
  background-color:@brand-primary;
  margin-bottom:30px;
  .nav-tabs li {
    width:25%;
    margin-bottom:0;
    a {
      color:#9473af;
      font-size:16px;
      font-family:'CorpidC1s-Bold';
      border:0;
      text-align:center;
      padding-top:15px;
      padding-bottom:15px;
    }
    a:hover,
    a:focus {
      background-color:@brand-primary;
      color:#ffffff;
      border:0;
      border-radius: 0;
    }
  }

  .nav-tabs li.active a {
    background-color:@brand-primary;
    color:#ffffff;
  }
  .nav-tabs li.last a {
    margin-right:0;
  }
}

//Videos

.retirement-hub-video-no-bg {
  margin-top:30px;
}

.wrapper-retirement-hub-videos {
  background-color:@brand-primary;
  margin-left:-9999em;
  margin-right:-9999em;
  padding-right:9999em;
  padding-left:9999em;
  margin-top:30px;
  color:#ffffff;
  padding-top:20px;
  padding-bottom:40px;
  h2 {
    margin-bottom:40px;
    font-size:22px !important;
  }
  .flex-video.widescreen {
    padding-bottom:53.25%;
  }
}
.homepage-videos h2 {
  margin-bottom:10px;
}

.homepage-videos p:last-child {
  margin-bottom:0
}

.homepage-videos .flex-video.widescreen {
  padding-bottom:51.10%;
}

//Tabs

.wrapper-retirement-hub-tabs {
  margin-top:60px;
  margin-left:-9999em;
  margin-right:-9999em;
  padding-left:9999em;
  padding-right:9999em;
  padding-bottom:40px;
  border-bottom:1px solid #dfe3e6;
  h5 {
    font-weight:bold;
  }
  .nav-tabs {
    margin-bottom:20px;
  }
  .nav-tabs li {
    a {
      font-family:'CorpidC1s-Bold';
      font-size:16px;
    }
    a:hover {
      background-color:#ffffff;
      color:#3b3b3b;
    }
  }
  //Accordions
  .panel {
    margin-top:14px;
  }
  .panel-heading {
    background-color:@brand-primary !important;
    color:#ffffff !important;
    padding:11px 15px;
  }
  .panel-heading a {
    color:#ffffff !important;
    background-image: url('/content/dam/plum/images/icons/arrows_new_wht.png');
    background-position: right -188px!important;
  }
  .panel-heading a.collapsed {
    color:#ffffff !important;
    background-image: url('/content/dam/plum/images/icons/arrows_new_wht.png');
  }
}

//Helpful articles
.wrapper-helpful-articles {
  padding-top:20px;
  h2 {
    color:@brand-primary;
    font-size:22px !important;
  }
  .helpful-article-img {
    margin-bottom:20px;
    margin-top:20px !important;
    background-color:#f2f4f6;
    padding:10px 15px;
    min-height:195px;
    h3 {
      color:@brand-primary;
    }
    img {
      margin-top:25px;
    }
  }
  .helpful-article {
    background-color:#f2f4f6;
    margin-bottom:40px;
    padding:10px 15px;
    h3 {
     color:@brand-primary;
    }
  }
  .helpful-article-img.heading {
      margin-top:0 !important;
  }
}

// Case studies

.retirement-hub-case-study {
   background-color:#f2f4f6;
   padding:20px 20px 10px 20px;
   margin-top:30px !important;
   margin-bottom:20px;
  h4 {
    color:@brand-primary !important;
    font-family: 'corpid-light';
    font-weight: bold;
    margin-top:0;
    margin-bottom:10px;
  }
  ul {
    padding-left: 15px;
    li {
      margin-bottom:10px;
    }
    li:last-child {
      margin-bottom:0;
    }
  }
}

//Media queries

@media screen and (min-width:768px) and (max-width:991px) {


  //Global

  .retirement-hub-intro h2 {
    margin-top:0px;
  }

  //Banner
  .wrapper-masthead {
      height:200px;
    }
    .wrapper-masthead-heading {
      top:50px;
      width:320px;
      height:150px;
    }
    .wrapper-masthead-heading h1 {
      width:65%;
      font-size:24px !important;
      margin-top:40px;
    }

    .in-retirement-heading h1,
    .focused-heading h1 {
      width:75%;
      margin-top:60px;
    }

    .wrapper-masthead-heading p {
      font-size:15px;
    }

    //Videos

  .wrapper-retirement-hub-videos .flex-video.widescreen {
    padding-bottom:52.90%;
  }

  .homepage-videos .flex-video.widescreen {
    padding-bottom:49.30%;
  }
}

   @media screen and (max-width:767px) {
     //Global

     .retirement-hub-intro h2 {
       margin-top:0px;
     }

     //Banner

     .wrapper-masthead {
      background-position:85% 0 !important;
      height:180px;
      margin-top:20px;
    }
    .wrapper-masthead-heading {
      width:240px;
      height:120px;
      position:absolute;
      top:60px;
    }

    .wrapper-masthead-heading h1 {
      font-size:20px !important;
      margin-top:30px;
   }

   .in-retirement-heading h1,
   .focused-heading h1 {
     margin-top:50px;
   }

   .wrapper-masthead-heading p {
     font-size:13px;
     width:95%;
   }

   //Secondary navigation

     .wrapper-retirement-hub-nav {
       margin-top:20px;
       background-color:transparent !important;
       margin-bottom:20px;
       .nav-tabs {
         border-bottom:1px solid #dfe3e6;
         padding-bottom:10px;
       }
       .nav-tabs li {
         width:100%;
         a {
           text-align:left;
                  padding:0;
                  font-family: 'corpid-light';
                  font-size:14px;
                  color:#572381;
                  padding-bottom:3px;
         }
         a:hover,
         a:focus {
           background-color:transparent;
                  color:#3b3b3b;
         }
       }
       .nav-tabs li.active a {
               background-color:transparent;
               color:#3b3b3b;
               font-weight:bold;
            }
     }

   //Tabs

   .wrapper-retirement-hub-tabs {
     margin-top:20px;
     h4 {
      margin-top:20px;
     }
     .nav-tabs {
       margin-bottom:10px;
       border-bottom:1px solid #dfe3e6;
       padding-bottom:10px;
       li {
         background-color:transparent;
         width:100%;
         border-left:0;
         margin-bottom:0;
         a {
           border:0;
           text-align:left;
           padding:0;
           font-family: 'corpid-light';
           font-size:14px;
           color:@brand-primary;
           padding-bottom:3px;
         }
         a:hover,
         a:focus {
           border:0;
           margin-right:0;
           background-color:transparent;
           color:#3b3b3b;
         }
       }
       li.active a {
         margin-right:0;
         color:#3b3b3b;
         font-weight:bold;
       }
       li.active a:hover {
         margin-right:0;
         background-color:transparent;
         color:#3b3b3b;
       }
     }
   }

   //Videos
    .retirement-hub-video-no-bg {
      margin-top:30px;
    }

     .wrapper-retirement-hub-videos {
       margin-top:20px;
       h2 {
         margin-bottom:20px;
       }
     }

    .homepage-videos .flex-video.widescreen {
      padding-bottom:56.25%;
    }

    //Helpful articles

     .wrapper-helpful-articles {
       .helpful-article-img {
         margin-top:0 !important;
         margin-bottom:20px;
         img {
           margin-top:40%;
         }
       }
       .helpful-article {
         margin-bottom:20px;
       }
     }

     //Case studies

     .retirement-hub-case-study {
       margin-top:20px;
       margin-bottom:0;
     }

 }
