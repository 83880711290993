.content-padding-top {
    padding-top: 60px;
}

.left-col-icon {
    img {
        height: 66px;
    }
}

.transition-portal {
    font-size: @font-size-body;
    font-family: @font-family-light;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.wrapper-hero-banner {
    height: 590px;

    .banner-content {
        position: relative;
        height: 495px;
        width: 595px;

        .text-image {
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
        }
    }

    h1 {
        font-size: 42px !important;
        line-height: 48px;
        margin-bottom: 15px;
        color: @brand-primary;
    }

    p {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0;
    }

    .banner-disclaimer p {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 22px;
    }

    .btn-primary {
        height: 45px;
        min-width: 165px;
        border-radius: 0;
        font-size: 20px;
        font-family: @font-family-bold;
        padding: 7px 16px 0 16px;
    }
}

//Bar Graph

.wrapper-lower-fees-graph {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid #cbc8c8;
    padding-bottom: 10px;
}

.wrapper-bar-chart {
    margin-bottom: 10px;

    p {
        margin: 0;
    }
}

.wrapper-low-bar {
    margin-top: 15px;

    p {
        margin-bottom: 0;
    }

    .low-bar {
        width: 160px;
        height: 110px;
        background-color: @brand-primary;
    }
}

.high-bar {
    width: 160px;
    height: 125px;
    background-color: #fab226;
}

//Accordion

.link-accordion {
    .panel-heading {
        padding-left: 0;
        background-color: transparent !important;
    }

    .panel-title {
        font-family: @font-family-light;

        a,
        a.collapsed {
            color: @brand-primary !important;
            font-size: 16px;
            text-decoration: underline;
            font-family: @font-family-light;
            background-image: none;
            position: relative;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        a:after {
            content: "\f077";
            font-family: "Font Awesome Pro Light";
            display: inline-block;
            margin-left: 10px;
            position: absolute;
            top: 2px;
        }

        a.collapsed:after {
            content: "\f078";
            font-family: "Font Awesome Pro Light";
            display: inline-block;
            margin-left: 10px;
            position: absolute;
            top: 2px;
        }
    }

    .list-item-text {
        padding: 20px 15px 0 0 !important;
        border: 0;
    }
}

//MySuper growth styles

.wrapper-mysuper-growth {
    background-size: 98% 100% !important;
    background-position: 100% 100% !important;
    height: 200px;

    h3 {
        margin-top: 0;
    }

    .wrapper-mysuper-content {
        padding-top: 50px;
    }
}

.wrapper-investments {
    background-color: @soft-grey;

    .link-accordion {
        .panel {
            background-color: @soft-grey !important;
        }
    }
}

.download-link:before {
    content: "\f019";
    font-family: "Font Awesome Pro Light";
    float: left;
    color: @brand-primary;
    margin-right: 10px;
    width: 20px;
    height: 40px;
}

.wrapper-how-to-join {
    background-color: @brand-primary;
    color: @white;

    a {
        color: @white;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .btn-primary {
        min-width: 160px;
        height: 45px;
        padding-top: 5px;
        color: @white;
        font-size: 20px;
        font-family: @font-family-bold;
        border-radius: 0;
        border: 3px solid @white;
        text-decoration: none;

        &:hover,
        &:focus {
            color: @brand-primary;
            text-decoration: underline;
            background-color: @white;
        }
    }
}

.wrapper-additional-benefits {
    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.wrapper-links {
    p {
        line-height: 0;
        margin: 0;
        padding: 0;
        height: auto;

        a {
            line-height: 22px;
            padding-bottom: 15px;
            display: block;
        }

        a[href=""] {
            display: none;
        }
    }
}

.transition-portal {
    .row-footer {
        font-size: 16px;
    }
}

//Join now form and confirmation page

.fund-details-table {
    padding: 35px 30px 25px 30px;

    .table {
        border-bottom: 0;

        tr {
            td {
                border-top: 0 !important;
                padding-right: 5px;
                padding-bottom: 0;
                padding-left: 0;
                vertical-align: middle !important;
            }
        }
    }
}

.wrapper-how-to-join {
    .fund-details-table {
        padding: 20px 0 0 0;

        .table {
            width: auto;
            margin-bottom: 0;

            td {
                padding: 0 !important;
                padding-bottom: 2px !important;

                &:nth-child(1) {
                    width: 45%;
                }

                a { 
                    color: @white !important;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.wrapper-get-in-touch {
    p {
        font-size: 16px;
    }

    h2, h3, h4 {
        margin-top: 5px;
    }
}

.footer-disclaimer {
    padding-top: 40px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(151,151,151,0.39);
    border-bottom: 1px solid rgba(151,151,151,0.39);

    p {
        font-size: 14px;
    }
}

@media screen and (min-width: 992px) {
    .hide-on-desktop {
        display: none;
    }

    .wrapper-hero-banner {
        background-position: center top !important;
    }
    
    .lower-fees-graph-content,
    .wrapper-low-bar {
        padding-left: 0;
    }

    .low-bar {
        width: 108px;
    }

    .wrapper-graph-content {
        position: relative;
        height: 190px;

        .graph-content {
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .graph-content p {
            margin-bottom: 0;
        }
    }

    .wrapper-mysuper-content {
        margin-left: -10px;
    }

    //Styles added to fix the sticky nav tabs - 15/02/2022 - TV

    .transition-portal {
        .navbar-default {
            background-color: transparent !important;
            border-color: transparent !important;
            border: 0;
        }

        #mlcStickyNav {
            .nav-item .nav-link {
                max-width: 160px !important;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .wrapper-hero-banner {
        background-position: 60% 50% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .spacing-md-pl-0 {
        padding-left: 0;
    }
    
    .spacing-md-pr-0 {
        padding-right: 0;
    }

    .hide-on-desktop {
        display: none;
    }
    
    .wrapper-hero-banner {
        background-size: cover !important;
        background-position: 60% 50% !important;

        .banner-content p {
            width: 465px;
        }

        .banner-disclaimer p {
            width: 75%;
        }
    }

    .wrapper-hero-banner.join-now-banner {
        .banner-content {
            width: 545px;
        }
    }

    .wrapper-mysuper-growth {
        background: url(/content/dam/plum/images/content/portal/mysuper-performance-mobile-plum.png) !important;
        background-position: 100% 100% !important;
        background-size: 96% 80% !important;
        background-repeat: no-repeat !important;
        height: 240px;

        .wrapper-super-link {
            margin-bottom: 40px;

            a {
                margin-left: 20px;
            }
        }

        .wrapper-mysuper-content {
            padding-top: 15px;
            br {
                display: none;
            }
        }
    }

    .wrapper-get-in-touch {
        br {
            display: none;
        }
    }
}

@media screen and (min-width: 768px) {
    a[href^="tel"]:link,
    a[href^="tel"]:visited,
    a[href^="tel"]:hover {
        text-decoration: none!important;
        pointer-events: none;
        cursor: default;
        color: inherit;
    }
  /* remove focus styling for href:tel elements */
  a[href^="tel"]:focus {
    text-decoration: none;
    outline: none;
    outline-offset: none;
  }
}

@media screen and (max-width: 991px) {
    .transition-portal {
        .navbar-default {
            border: 0 !important;
        }

        #mlcStickyNav {
            #mlcStickyNavContent {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

        .wrapper-get-in-touch br {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .content-padding-top {
        padding-top: 30px;
      }

      .hide-on-mobile {
          display: none;
      }
    
    .wrapper-hero-banner {
        height: 310px;
        position: relative;
        background-size: cover !important;
        background-position: 75% 50% !important;
    }

    .wrapper-banner-content-mobile {
        padding-top: 30px;
        padding-bottom: 15px;

        br {
            display: none;
        }

        h1 {
            color: @brand-primary;
            font-size: 38px !important;
            line-height: 44px;
            margin-bottom: 15px;
        }

        p {
            font-size: 20px;
            line-height: 26px;
        }

        .banner-disclaimer {
            p {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    
    .wrapper-bar-chart {
        width: 100%;
        margin: 0 auto;
    }

    .wrapper-high-bar {
        padding-left: 0;
        padding-right: 0;

        .high-bar {
            width: 140px;
        }
    }

    .wrapper-medium-bar {
        padding-left: 0;
        padding-right: 0;
    }

    .wrapper-low-bar {
        padding-left: 0;
        padding-right: 0;
        margin-top: 15px;

        .low-bar {
            width: 140px;
        }
    }

    .lower-fees-graph-content {
        text-align: center;
    }

    .wrapper-mysuper-growth {
        background: url(/content/dam/plum/images/content/portal/mysuper-performance-mobile-plum.png) !important;
        background-position: 100% 100% !important;
        background-size: 96% 80% !important;
        background-repeat: no-repeat !important;
        height: 200px;

        .wrapper-mysuper-content {
            padding-top: 15px;
        }

        br {
            display: none;
        }
    }

    .wrapper-investments {
        .mysuper-investment-table {
            table {
                tr {
                    border-bottom: 1px solid #c8c6c2;

                    &:nth-child(1) {
                        border-bottom: 0;
                    }

                    th {
                        display: none;
                    }

                    td {
                        text-align: left;
                        border-top: 0 !important;
                        display: block;
                        width: 100%;

                        &:nth-child(1) {
                            padding-top: 20px;
                        }

                        &:nth-child(2):before {
                            content: "1 month return";
                            display: inline-block;
                            width: 75%;
                            font-family: @font-family-bold;
                        }

                        &:nth-child(3):before {
                            content: "3 month return";
                            display: inline-block;
                            width: 75%;
                            font-family: @font-family-bold;
                        }

                        &:nth-child(4):before {
                            content: "1 year return";
                            display: inline-block;
                            width: 75%;
                            font-family: @font-family-bold;
                        }

                        &:nth-child(5):before {
                            content: "5 year return";
                            display: inline-block;
                            width: 75%;
                            font-family: @font-family-bold;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    .wrapper-benefit-content,
    .wrapper-intro-and-video,
    .wrapper-get-in-touch {
        br {
            display: none;
        }
    }

    .fund-details-table {
        padding: 20px 10px 20px 10px;

        .table {
            tr {
                td {
                    padding-left: 0 !important;
                }
            }
        }
    }

    .join-now-banner {
        .hero-banner-content {
            height: 230px;
        }
    }

    .wrapper-form {
        margin-top: 200px;
    }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
    .wrapper-bar-chart {
        width: 500px;
        margin: 0 auto;
    }
}