.fee-repricing-container {
  width: 100%;
  margin: 0 auto 30px auto;
}

.graph-container {
  min-height: 325px;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .col-custom {
    padding: 0;
    width: 105px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 0;
  }

  .column-bar1,
  .column-bar2,
  .column-bar3 {
    width: 90px;
    margin: 0 auto 10px auto;
  }

  .column-bar1 {
    height: 150px;
    background-color: #572381;
  }

  .column-bar2 {
    height: 175px;
    background-color: #fab226;
  }

  .column-bar3 {
    height: 225px;
    background-color: #fab226;
  }

  .bar-heading {
    width: 100%;
    text-align: center;
    border-top: 1px solid #c8cbcb;
    height: 100px;
    padding-top: 10px;
    display: block;
    margin-top: 10px;
  }

  .wrapper-column-bar1 .text-image {
    text-align: center;
  }

  .industry-fees-amount, .fund-fees-amount {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .graph-container {
    .col-custom {
      width: 150px;
    }

    .column-bar1,
    .column-bar2,
    .column-bar3 {
      width: 100px;
    }
  }
}
