//Plum Retirement Hub - styles for new templates
// Reference blogOverview.css
//
// font-families used for the redesigned Plum site have been signed-off by product owners Philip Augustine and Andrew Lawless
.retirement-hub {
  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0!important;
    line-height: 1.4!important;
  }
  h1 {
    font-family: @font-family-heavy;
    font-size: 24px!important;
    color: #572381!important;
    line-height: 1.4!important;
  }
  h2 {
    font-size: 18px!important;
    color: #572381!important;
    font-family: @font-family-heavy;
  }
  h3 {
    font-size: 16px!important;
    color: #572381!important;
    font-family: @font-family-heavy;
  }
  h4, h5, h6 {
    color: #000;
    font-family: @font-family-heavy;
  }
  ol li,
  ul li {
    font-family: @font-family-light;
    font-weight: 200!important;
    font-size: 15px!important;
    line-height: 1.4!important;
    color: #000;
    margin-bottom: 10px!important;
  }
  p {
    line-height: 1.4;
    margin-bottom: 15px;
    font-size: 15px!important;
    color: #000;
    font-family: 'CorpidC1s-Light';
    font-weight: 400!important;
  }
  strong {
    font-family: 'CorpidC1s-Light';
    color: #000;
    font-weight: 800!important;
  }
  small {
    font-size: 12px;
  }
  a {
    text-decoration: underline;
    &:focus {
      outline: #3b3b3b dotted 1px!important;
      outline-offset: -2px;
    }
  }

  .row-footer {
    p, strong, b {
      font-size: 12px!important;
      line-height: 1.8!important;
      font-family: 'CorpidLight';
      color: #3b3b3b!important;
    }
    .nav-tabs {
      margin: 9px 0!important;
      li {
        border: none!important;
        font-family: 'CorpidLight';
        line-height: 10px!important;
        a {
          font-family: 'CorpidC1s-Regular';
          font-size: 15px!important;
          letter-spacing: -0.3px;
          margin-top: 0!important;
          &:hover,
          &:focus {
            text-decoration: none!important;
            font-weight: 200!important;
            color: #572381!important;
          }
        }
      }
    }
  }

  .colwidth-25percentage .table th {
    padding: 15px;
    width: 25%;
  }

  /* Glyphs */
  .glyphicon-tick:before,
  .glyphicon-ok:before {
    font-family: 'Glyphicons Halflings';
    content: "\e013";
    font-weight: 200;
    color: #572381;
    line-height: 2.6;
    margin-left: 10px;
  }

  .glyphicon-cross:before,
  .glyphicon-remove:before {
    font-family: 'Glyphicons Halflings';
    content: "\e014";
    font-weight: 200;
    color: #C20000;
    line-height: 2.6;
    margin-left: 10px;
  }

  .glyphicon-earphone:before,
  .glyphicon-phone:before {
    font-family: 'Glyphicons Halflings';
    content: "\e182";
    font-weight: 200;
    color: #c20000;
  }

  .glyphicon-new-window:before {
    font-family: 'Glyphicons Halflings';
    content: "\e164";
    color: rgba(87, 35, 129, 0.9);
    font-size: 10px;
  }

  .glyphicon-time:before {
    color: #572381;
  }

  // hacky fix for empty list element in navigation.
  // known AEM issue since 2013
  //.navbar-nav li.last {
  //  display: none;
  //}

  .navbar-toggle:focus {
    outline: 5px auto -webkit-focus-ring-color!important;
  }

  .hub-menu {
    background: #f2f4f6!important;
  }
  .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .landing-page {
    h2 {
      font-size: 18px!important;
    }
    p {
      font-size: 15px!important;
    }
  }
  .media {
    overflow: auto;
    h2 {
      a {
        display: inline-block;
        padding: 1px 0;
        letter-spacing: 0.3px;
      }
      a:focus,
      &:focus {
        outline: #3b3b3b dotted 1px!important;
        outline-offset: -2px;
        text-decoration: none!important;
        color: #000;
      }
    }
    a {
      text-decoration: underline;

      &:focus > span {
        outline: #3b3b3b dotted 1px!important;
        outline-offset: -2px;
        text-decoration: underline!important;
        background: #a92582;

        &:before {
          background: #fff;
          color: purple;
          width: 10px;
          padding: 10px 0px;
        }
      }
      &:hover {
        text-decoration: none!important;
        color: #000;
      }
    }
  }
  .get-in-touch .media a[href^="tel"]:focus>span {
      background: none;
  }

  .boxed-item {
    height: 255px;
    img {
      margin: 10px 0;
    }
  }
  .hero-image {
    background-image: url("/content/dam/plum/images/icons/hero-banner-retirement.jpg");
    background-size: cover!important;
    height: 310px;
    background-position: top center!important;
    background-repeat: no-repeat;
    &:before {
      background: rgba(0, 0, 0, 0.2);
      content: "";
      z-index: 0;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      height: inherit;
      width: 100%;
    }
    .text-overlay {
      h1 {
        color: #fff!important;
        font-size: 40px!important;
        font-family: 'CorpidC1s-Regular';
        font-weight: 900!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
      p {
        color: #fff!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        font-size: 20px!important;
      }
    }
  }

  .lighter-hero-image {
    background-image: url("/content/dam/plum/images/icons/banner-boost-your-super.jpg");
    background-size: cover!important;
    height: 310px;
    background-position: top center!important;
    background-repeat: no-repeat;
    &:before {
      content: "";
      z-index: 0;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      height: inherit;
      width: 100%;
    }
    .text-overlay {
      h1 {
        color: #fff!important;
        font-size: 40px!important;
        font-family: 'CorpidC1s-Regular';
        font-weight: 900!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
      p {
        color: #fff!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        font-size: 20px!important;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .lighter-hero-image {
      background-image: url("/content/dam/plum/images/icons/banner-boost-your-super.jpg");
      background-size: cover!important;
      height: 310px;
      background-position: top center!important;
      background-repeat: no-repeat;
      &:before {
        background: rgba(0, 0, 0, 0.2);
        content: "";
        z-index: 0;
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        width: 100%;
      }
      .text-overlay {
        h1 {
          color: #fff!important;
          font-size: 40px!important;
          font-family: 'CorpidC1s-Regular';
          font-weight: 900!important;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        }
        p {
          color: #fff!important;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          font-size: 20px!important;
        }
      }
    }
  }

  @media screen and (min-width: 993px) {
    .lighter-hero-image {
      background-image: url("/content/dam/plum/images/icons/banner-boost-your-super.jpg");
      background-size: cover!important;
      height: 310px;
      background-position: top center!important;
      background-repeat: no-repeat;
      &:before {
        content: "";
        z-index: 0;
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        width: 100%;
      }
      .text-overlay {
        h1 {
          color: @primary-purple!important;
          font-size: 40px!important;
          font-family: 'CorpidC1s-Regular';
          font-weight: 900!important;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        }
        p {
          color: @primary-black!important;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          font-size: 20px!important;
        }
      }
    }
  }

  .hero-banner {
    background-image: url("")!important;
    background-color: @soft-grey;
    background-size: cover!important;
    height: 310px;
    background-position: top center!important;
    background-repeat: no-repeat;
    &:before {
      background: rgba(0, 0, 0, 0.2);
      content: "";
      z-index: 0;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      height: inherit;
      width: 100%;
    }
    .text-overlay {
      h1 {
        color: #fff!important;
        font-size: 40px!important;
        font-family: @font-family-regular;
        font-weight: 900!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
      p {
        color: @white!important;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
        font-size: 20px!important;
      }
    }
  }

  .horizontal-align {
    display: flex;
    align-items: flex-end;
  }

  .lg-Heading {
    h1, h2, h3, h4 {
      line-height: 1.4!important;
    }
  }

  .md-Heading {
    h1, h2, h3, h4 {
      line-height: 1.4!important;
    }
  }

  .sm-Heading {
    font-family: 'CorpidC1s-Bold';
    font-weight: 500!important;
    color: #572381;
    h1, h2, h4, h4, p>strong {
      font-size: 18px!important;
      line-height: 1.4!important;
    }
    img {
      max-width: 30px;
      margin: 0 0 5px;
    }
  }

  .xs-Heading {
    h1, h2, h3, h4 {
      line-height: 1.4!important;
    }
  }



  .pageLinksWrapper {
    margin-bottom: 20px;
    h2, h3, h4 {
      padding-top: 5px;
    }
  }

  .featured-intro {
    .container {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .intro-icn {
      img {
        margin-top: -20px;
        margin-right: 20px;
      }
    }
  }

  .intro-icn {
    img {
      max-width: 45px;
      min-width: 30px;
      margin-top: 0;
      margin-right: 20px;
    }
  }

  .intro-icn2 {
    img {
      margin: 5px 0;
      padding-right: 20px;
      max-width: 180px;
    }
  }

  .intro-icn3 img {
    height: 35px;
    max-width: 100%;
    margin-top: 5px;
  }

  .intro-icn3.media>.pull-left {
    padding-right: 15px!important;
    width: 60px;
  }


  .styled-list {
    ol {
      padding-left: 10px;
      margin-left: 10px;
      margin-bottom: 0;
      li {
        line-height: 1.4;
        margin-bottom: 10px;
        padding-left: 12px;
      }
    }
    ul {
      padding-left: 10px;
      margin-left: 10px;
      margin-bottom: 0;
      li {
        line-height: 1.4;
        margin-bottom: 10px;
        padding-left: 12px;
      }
    }
  }

  .pageLinks ul {
    padding: 0;
  }
  .pageLinks {
    li {
      font-family: 'CorpidC1s-Light';
      font-weight: 400!important;
      font-size: 15px!important;
      border-top: 1px solid #dcdad6;
      list-style: none;
      margin: 0!important;
      padding: 0;
      width: 100%;
      &:last-child {
        border-bottom: 1px solid #dcdad6;
      }
      a {
        font-family: 'CorpidC1s-Light';
        font-weight: 400!important;
        font-size: 15px;
        color: #000;
        display: block;
        position: relative;
        padding: 12px 20px 12px 0;
        text-decoration: none;
        width: 100%;
        letter-spacing: -0.1px;
        &:focus {
          outline: #3b3b3b dotted 1px!important;
          outline-offset: -2px;
          text-decoration: underline!important;
        }
        &:hover {
          outline: 0!important;
          color: #572381;
          text-decoration: underline!important;
        }
        &:after {
          font-family: 'Glyphicons Halflings';
          font-weight: 200;
          content: "\e080";
          font-size: 8px;
          color: rgba(0, 0, 0, 0.6);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-style: normal;
          font-variant: normal;
          line-height: 1;
          margin-top: -6px;
          position: absolute;
          right: 11px;
          speak: none;
          text-transform: none;
          top: 55%;
        }
      }
    }
  }


.glyphicon-pencil:before {
  color: #572381!important;
}
.pageLinks li > a:focus:after,
.pageLinks li > a:hover:after {
  color: #572381;
}

.wrapper-bg-faf8f4.nav-right {
  .nav-tabs>li,
  .nav-tabs>li.active,
  .nav-tabs>li.first,
  .nav-tabs>li.last {
    border-bottom: 1px solid #e4e4e4;
    &:hover {
      border-bottom: 1px solid #e4e4e4;
      text-decoration: underline;
    }
  }
  .nav-tabs {
    margin-top: 10px;
    border-bottom: 1px solid transparent!important;

    > li a:focus:after {
      color: #000;
      background: transparent!important;
    }
    >li:last-child {
      border-bottom: 1px solid transparent!important;
      &:hover {
        border-bottom: 1px solid transparent!important;
      }
    }
  }
}


.breadcrumb {
  color: #572381!important;
  background-color: #fff;
  padding: 8px 0;
  font-size: 15px;
  font-weight: 200;
  li {
    margin-bottom: 0;
    padding-left: 0;
    a {
      color: #572381;
      padding: 10px 0;
    }
  }
  li+li:before {
    color: #572381;
  }
  >.active {
    color: #000;
    font-family: 'CorpidC1s-Light';
    font-size: 15px;
  }
}

  .mlc-primary-nav li, .nav-pills li, .nav-tabs li, .sidekick li {
    margin-bottom: 0;
    padding-left: 0;
  }
  .crumbcontainer,
  .market-watch .content-title {
    display: block!important;
  }

  .nav-tabs>li.first {
    border-top: 0 solid #ccc;
  }
  .hid-li-active li.active {
    display: none;
  }
  .wrapper-bg-faf8f4.nav-right .nav-tabs>li {
    font-family: 'CorpidC1s-Light';
    font-weight: 400!important;
    font-size: 15px;
    width: 100%;
    background-color: transparent;
    border-left: 0;
    color: #572381;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 0!important;
    &:after {
      font-family: 'Glyphicons Halflings';
      content: '\e080';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal;
      font-variant: normal;
      speak: none;
      text-transform: none;
      font-size: 7px;
      font-weight: 200;
      position: absolute;
      right: 11px;
      color: #572381;
      float: right;
      line-height: 1;
      top: 18px;
    }
    a {
      position: relative;
      width: 100%;
      text-align: left;
      padding-left: 0;
      padding-right: 0;
      font-family: 'CorpidC1s-Light';
      font-size: 15px;
      font-weight: 400!important;
      color: #572381;
      text-decoration: none;
      letter-spacing: -0.4px;
      &:focus {
        background: transparent;
        outline: #3b3b3b dotted 1px!important;
        outline-offset: -2px;
        text-decoration: underline!important;
      }
      &:hover {
        text-decoration: underline!important;
        background: transparent!important;
        border: 1px solid transparent!important;
        border-top: 1px solid #dcdad6;
      }
      &:active {
        color: #000!important;
        text-decoration: none!important;
      }
    }
  }
  .feature-copy {
    font-size: 18px;
  }
  .featured-item {
    background-color: #f2f4f6!important;
    h2 {
      font-family: 'CorpidC1s-Heavy';
      color: #572381;
    }
    h2 {
      font-size: 18px!important;
    }
    h3, h4 {
      font-family: 'CorpidC1s-Heavy';
      font-size: 16px!important;
      color: #000!important;
    }
    p {
      font-family: 'CorpidC1s-Light';
      font-size: 15px!important;
      color: #000!important;
      font-weight: 400!important;
      strong, b {
        font-family: 'CorpidC1s-Bold'!important;
        color: #572381!important;
        font-size: 18px!important;
        font-weight: 200;
      }
    }
    a {
      font-family: 'CorpidC1s-Light';
      font-size: 15px;
      color: #572381;
      text-decoration: underline!important;
      &:hover {
        text-decoration: none;
      }
    }
    span.btn-cta-primary:focus {
      background-color: #a92582!important;
      text-decoration: underline!important;
      outline: #3b3b3b dotted 1px!important;
      outline-offset: -2px;
      border: 1px solid #a92582;
    }
    .btn-cta-primary {
      font-family: 'CorpidC1s-Heavy';
      font-weight: 600!important;
      font-size: 15px;
      min-width: 195px;
      height: 45px;
      padding: 10px 5px!important;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      border: 1px solid #572381;
      &:focus,
      &:hover {
        background-color: #a92582!important;
        text-decoration: underline!important;
        border: 1px solid #a92582;
      }
    }
  }
  .newTag,
  .new-text {
    font-family: 'CorpidC1s-Light';
    background-color: #a92482;
    color: #fff;
    padding: 2px;
    font-size: 12px;
    font-weight: 400!important;
    text-transform: uppercase;
    &:focus,
    &:hover {
      text-decoration: none!important;
    }
  }
  .nav>li>a {
    &:focus {
      outline: #3b3b3b dotted 1px!important;
      outline-offset: -2px;
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
      color: black;
      border: none;
    }
  }
  iframe {
    border-bottom: 1px solid #c8c6c2;
  }

  // rich text editor styles
  .h1-hybrid {
    font-family: 'CorpidC1s-Regular';
    color: #572381;
    font-size: 24px;
    font-weight: 900!important;
    vertical-align: top;
  }

  .h2-hybrid {
    font-family: 'CorpidC1s-Heavy';
    color: #572381;
    font-size: 18px;
    font-weight: 900!important;
    vertical-align: top;
  }

  .h3-hybrid {
    font-family: 'CorpidC1s-Regular';
    color: #572381;
    font-size: 16px!important;
    font-weight: 900;
    vertical-align: top;
  }

  .h4-hybrid {
    font-family: 'CorpidC1s-Heavy';
    color: #000;
    font-size: 16px;
    font-weight: 900!important;
    vertical-align: top;
  }

  .new-text {
    font-family: @font-family-light;
    background-color: #a92482;
    color: #fff;
    padding: 2px;
    font-size: 12px;
    font-weight: 400!important;
    text-transform: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin:-1px;
    padding:0;
    overflow:hidden;
    clip:rect(0,0,0,0);
    border:0
  }
  .sr-only-focusable:active,
  .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow:visible;
    clip:auto
  }

  .shaded-background {
    background-color: #f2f4f6;
  }

  .disclaimer {
    h2, h3 {
      font-family: @font-family-light!important;
      font-size: 14px!important;
      font-weight: 900!important;
      color: #000!important;
    }
    p {
      font-family: @font-family-light!important;
      font-size: 14px!important;
      font-weight: 400!important;
      color: #000!important;
    }
  }

  .small {
    font-size: 13px!important;
  }

  caption {
    font-size: 18px;
    text-align: center;
    padding-bottom: 20px;
  }

  .table_stacked1 {
    width: 100%;
  }

  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>td,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>thead>tr>th {
    white-space: inherit;
  }
   .table>tbody>tr>th,  .table>thead>tr>th {
    background-color: #572381;
    color: #fff;
  }
   .table tr td,  .table tr th {
    padding: 15px;
    border: 2px solid #fff!important;
    vertical-align: top;
  }
  .table tr td,
  .table_stacked1.table tr td {
    color: #000!important;
  }
  .table_stacked1.table th {
    background-color: #572381;
    color: #fff;
    text-align: left;
  }
  .table_stacked1.table td,
  .table_stacked1.table th {
    padding: 15px 20px;
    border: 2px solid #fff!important;
  }
  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.4;
  }
  .table-responsive {
    -webkit-overflow-scrolling: touch;
    border: 0;
  }
  .table-responsive {
    overflow-x: auto;
    min-height: .01%;
  }
  .table {
    border: 0;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
  }
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 21px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
  }
  .table th {
    font-family: @font-family-heavy;
    font-size: 15px;
    color: #fff;
    background: #572381;
  }

  .table tr:nth-child(even),
  .table tr:nth-child(even) > th[scope="row"] {
    background-color: #f2f4f6;
    color: #000;
  }
  .table tr:nth-child(odd) > th[scope="row"] {
    background-color: #fff;
    color: #000;
  }

  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>td,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>thead>tr>th {
    white-space: inherit;
  }
  .table>tbody>tr>th,
  .table>thead>tr>th {
    background-color: #572381;
    color: #fff;
    font-family: @font-family-light;
    font-size: 15px;
    font-weight: 400!important;
  }
  .table tr td,
  .table tr th {
    padding: 15px;
    border: 2px solid #fff!important;
    font-family: @font-family-light;
    font-size: 15px;
    font-weight: 400!important;
    vertical-align: top;
  }
  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.4;
  }
  td, th {
    border: none!important;
  }
  th {
    text-align: left;
  }
  td, th {
    padding: 0;
  }
  th {
    font-weight: bold;
    text-align: -internal-center;
  }
  td, th {
    display: table-cell;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
  }

  thead {
    font-family: @font-family-light;
    font-size: 15px;
    color: #fff;
    background: #572381;
  }

  td {
    color: #000!important;
  }
}

// Added to override navbar global inheritance
.btn-login p {
    line-height: unset!important;
    margin: 0 0 10px !important;
}
.btn-forgot-password p {
    line-height: unset!important;
    margin: 0;
}

@media screen and (max-width: 767px) {
  .retirement-hub {
    .boxed-item {
      height: 230px!important;
      border-bottom: 1px solid #dcdad6!important;
    }
    .hero-image {
      height: 180px!important;
      padding: 0!important;
      margin: 0!important;
      .text-overlay {
        h1 {
          font-size: 22px!important;
        }
        p {
          font-size: 15px!important;
        }
      }
    }

    .ligher-hero-image {
      height: 180px!important;
      padding: 0!important;
      margin: 0!important;
      .text-overlay {
        h1 {
          font-size: 22px!important;
        }
        p {
          font-size: 15px!important;
        }
      }
    }

    .table-responsive {
      width: 100%;
      margin-bottom: 15.75px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive {
      overflow-x: auto;
      min-height: .01%;
    }

    .table-responsive>.table {
      margin-bottom: 0;
    }
    .table {
      border: 0;
      border-bottom: 1px solid #ddd;
    }

    .table_stacked1 tr {
      margin-bottom: 20px;
      border: 0!important;
    }
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
      white-space: nowrap;
    }
    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
      width: 15%;
    }
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
      white-space: nowrap;
    }
    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
      width: 15%;
    }

    .row-footer {
      p, strong, b {
        font-size: 12px!important;
        line-height: 1.8!important;
      }
      .nav-tabs {
        margin: 10px 0 15px!important;
        li {
          border: none!important;
          a {
            margin: 0!important;
            line-height: 10px!important;
            &:hover {
              font-weight: 200!important;
            }
          }
        }
      }
    }

    table.table_stacked2{
      border: 0!important;
      border-collapse: collapse;
      border-spacing: 0;
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
    }
    table.table_stacked2 tbody, table.table_stacked2 tr {
      display: block;
      padding: 0;
      text-align: left;
      white-space: normal;
    }
    .table_stacked2 tr {
      border: 0!important;
      margin-bottom: 20px;
    }
    table.table_stacked2 th {
      display: none;
    }
    table.table_stacked2 td {
      border-bottom: 1px !important;
      border-top: 0px !important;
    }
    table.table_stacked2 td, table.table_stacked2 th {
      padding: 15px 20px!important;
    }
    table.table_stacked2 tr td, table.table_stacked2 tr th {
      overflow: hidden;
      position: relative;
      vertical-align: top;
      white-space: inherit!important;
      width: 100%;
    }
    table.table_stacked2 tr th[scope=row] {
      background-color: #572381!important;
      color: #fff!important;
      display: block;
      height: auto;
      margin: 0 auto;
      padding-top: 20px!important;
      padding-bottom: 20px!important;
      text-align: center;
      width: 100%;
    }
    table.table_stacked2 td:nth-child(even) {
      background-color: #f2f4f6;
      display: block;
    }
    table.table_stacked2 td:nth-child(odd) {
      background-color: #fff;
      display: block
    }
    table.table_stacked2 td[headers]:before {
      content: attr(headers);
      display: block;
      float: left;
      margin-bottom: 5px;
      text-align: left;
      width: 55%;
      padding: 0 1em 0 0;
      font-weight: 900;
    }
    .table_stacked2.table td[headers]>* {
      clear: right;
      display: block;
      float: right;
      padding-left: 1em;
    }
    .table_stacked2 img {
      margin-right: 25%!important;
      margin-top: 5px!important;
    }

  }
}

@media screen and (max-width: 992px) {
  //Boost your super pages dont have .retirement-hub class
  .boostyoursuper-breadcrumb {
    display: none !important;
  }

  .retirement-hub {
    .bootstrap-nav-breadcrumb {
      display: none;
    }
    .navbar-nav li a {
      line-height: 1.4!important;
      padding: 5px 15px!important;
    }
    .nav-tabs>li,
    .nav-tabs>li.active,
    .nav-tabs>li.first,
    .nav-tabs>li.last {
      border-bottom: 1px solid #e4e4e4;
      &:hover {
        border-bottom: 1px solid #e4e4e4;
        text-decoration: underline;
      }
    }
    .row-footer {
      p, strong, b {
        font-size: 12px!important;
        line-height: 1.8!important;
      }
      .nav-tabs {
        li {
          border: none!important;
          &:hover {
            text-decoration: none;
          }
          a {
            &:hover {
              font-weight: 200!important;
            }
          }
        }
      }
    }
    .hero-image,
    .lighter-hero-image {
      background-position: 65% 50%!important;
      height: 240px;
      padding: 0!important;
      &:before {
        background: rgba(0, 0, 0, 0.6);
        content: "";
        z-index: 0;
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        width: 100%;
      }
      .text-overlay {
        p {
          max-width: 50%;
        }
      }
    }
    .boxed-item {
      height: 255px;
      border-bottom: none;
    }
    .btn-cta-primary {
      min-width: 195px;
    }
  }
}

/* tel: protocol link behaviour enabled for smaller screens */
@media screen and (max-width: 767px) {
  a[href^="tel"]:link,
  a[href^="tel"]:visited,
  a[href^="tel"]:hover {
    text-decoration: underline;
    pointer-events: auto;
    cursor: pointer;
    color: @primary-purple!important;
  }
  .row-footer {
    .get-in-touch {
      .col-custom {
        .text-image {
          a[href^="tel"]:link span b,
          a[href^="tel"]:link span strong {
            font-size: 15px!important;
            line-height: 1.4!important;
            font-weight: 200!important;
            text-decoration: underline;
            color: @primary-purple!important;
          }
        }
      }
    }
  }
  .lighter-hero-image {
    background-position: 58% 80%!important;
  }
}

/* tel: protocol link behaviour disabled for larger screens */
@media screen and (min-width: 768px) {
  a[href^="tel"]:focus,
  a[href^="tel"]:link,
  a[href^="tel"]:visited,
  a[href^="tel"]:hover {
    text-decoration: none!important;
    pointer-events: none;
    cursor: default;
    color: inherit;
    display: inline-block;
  }
  /* remove focus styling for href:tel elements */
  a[href^="tel"]:focus {
    text-decoration: none!important;
    outline: none!important;
    outline-offset: none!important;
  }
}

/* Get in touch component */
.row-footer {
  .get-in-touch {
    .col-custom {
      h2 {
        font-family: @font-family-heavy;
        font-size: 30px!important;
        font-weight: normal;
        text-align: center;
        color: @primary-purple;
      }
      .text-image {
        h3 {
          font-family: @font-family-bold;
          font-size: 18px!important;
          color: @primary-purple;
        }
        p {
          font-family: @font-family-light;
          font-size: 15px!important;
          font-weight: 200;
          line-height: 1.4!important;
          color: @primary-black!important;
          text-align:center;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
            color: @primary-black;
          }
          &:focus {
            outline: #3b3b3b dotted 1px!important;
            outline-offset: -2px;
          }
        }
        strong, b {
          font-family: @font-family-light;
          font-size: 15px!important;
          line-height: 1.4!important;
          font-weight: 700!important;
          color: @primary-black!important;
        }
      }
    }
  }
}
