.wrapper-tabs .nav-tabs {
    border-bottom: 1px solid #67787e;

    li {
        margin-bottom: 0;
        margin-right: 50px;

        a {
            border: 0;
            border-radius: 0;
            font-size: @font-size-body;
            line-height: 22px;
            font-family: @font-family-regular;
            color: @primary-black;
            border-bottom: 3px solid transparent;

            &:hover,
            &:focus {
                color: @brand-primary;
                background-color: transparent;
                border-bottom: 3px solid @brand-primary;
            }
        }

        &.active a {
            border: 0;
            border-bottom: 3px solid @brand-primary;
            color: @brand-primary;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .wrapper-tabs .nav-tabs {
        li {
            margin-right: 40px;
        }
    }
}

@media screen and (max-width: 767px) {
    .wrapper-tabs {
        .nav-tabs {
            border-bottom: 0;

            li {
                width: 100%;
                margin-right: 0;

                a {
                    padding-left: 0 !important;
                }

                &.active a {
                    margin-bottom: 10px;
                }
            }
        }
    }
}