// placeholder for fonts

@font-face {
src: url("../resources/fonts/corpid/CorpidC1_3.eot");
src: local('?'), url("../resources/fonts/corpid/CorpidC1_3.woff") format("woff"), url("../resources/fonts/corpid/CorpidC1_3.svg#CorpidC1_3") format("svg");
font-family: 'CorpidLight';
font-weight: normal;
font-style: normal;
}

@font-face {
src: url("../resources/fonts/corpid/CorpidC1_8.eot");
src: local('?'), url("../resources/fonts/corpid/CorpidC1_8.woff") format("woff"), url("../resources/fonts/corpid/CorpidC1_8.svg#CorpidC1_8") format("svg");
font-family: 'CorpidBold';
font-weight: normal;
font-style: normal;
}

@font-face {
src: url("../resources/fonts/corpid/corpid-b-webfont.eot");
src: local('?'), url("../resources/fonts/corpid/corpid-b-webfont.woff") format("woff"), url("../resources/fonts/corpid/corpid-b-webfont.svg#CorpidC1_8") format("svg");
font-family: 'CorpidHeavy';
font-weight: normal;
font-style: normal;
}



// Corpid fonts
@font-face{
  font-family:"CorpidC1s-Light";
  src:url("../resources/fonts/corpid/CorpidC1s-Light.eot?#iefix");
  src:url("../resources/fonts/corpid/CorpidC1s-Light.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/CorpidC1s-Light.woff2") format("woff2"),
  url("../resources/fonts/corpid/CorpidC1s-Light.woff") format("woff"),
  url("../resources/fonts/corpid/CorpidC1s-Light.ttf") format("truetype"),
  url("../resources/fonts/corpid/CorpidC1s-Light.svg#CorpidC1s-Light") format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
}

@font-face{
  font-family:"CorpidC1s-Regular";
  src:url("../resources/fonts/corpid/CorpidC1s-Regular.eot?#iefix");
  src:url("../resources/fonts/corpid/CorpidC1s-Regular.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/CorpidC1s-Regular.woff2") format("woff2"),
  url("../resources/fonts/corpid/CorpidC1s-Regular.woff") format("woff"),
  url("../resources/fonts/corpid/CorpidC1s-Regular.ttf") format("truetype"),
  url("../resources/fonts/corpid/CorpidC1s-Regular.svg#CorpidC1s-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
}

@font-face{
  font-family:"CorpidC1s-Bold";
  src:url("../resources/fonts/corpid/CorpidC1s-Bold.eot?#iefix");
  src:url("../resources/fonts/corpid/CorpidC1s-Bold.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/CorpidC1s-Bold.woff2") format("woff2"),
  url("../resources/fonts/corpid/CorpidC1s-Bold.woff") format("woff"),
  url("../resources/fonts/corpid/CorpidC1s-Bold.ttf") format("truetype"),
  url("../resources/fonts/corpid/CorpidC1s-Bold.svg#CorpidC1s-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
}

@font-face{
  font-family:"CorpidC1s-Heavy";
  src:url("../resources/fonts/corpid/CorpidC1s-Heavy.eot?#iefix");
  src:url("../resources/fonts/corpid/CorpidC1s-Heavy.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/CorpidC1s-Heavy.woff2") format("woff2"),
  url("../resources/fonts/corpid/CorpidC1s-Heavy.woff") format("woff"),
  url("../resources/fonts/corpid/CorpidC1s-Heavy.ttf") format("truetype"),
  url("../resources/fonts/corpid/CorpidC1s-Heavy.svg#CorpidC1s-Heavy") format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
}

@font-face{
  font-family:"CorpidC1s-Black";
  src:url("../resources/fonts/corpid/CorpidC1s-Black.eot?#iefix");
  src:url("../resources/fonts/corpid/CorpidC1s-Black.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/CorpidC1s-Black.woff2") format("woff2"),
  url("../resources/fonts/corpid/CorpidC1s-Black.woff") format("woff"),
  url("../resources/fonts/corpid/CorpidC1s-Black.ttf") format("truetype"),
  url("../resources/fonts/corpid/CorpidC1s-Black.svg#CorpidC1s-Black") format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  font-stretch: normal;
}

// font-icon packs
@font-face{
  font-family:"glyphicons-halflings-regular";
  src:url("../resources/fonts/corpid/glyphicons-halflings-regular.eot?#iefix");
  src:url("../resources/fonts/corpid/glyphicons-halflings-regular.eot?#iefix") format("eot"),
  url("../resources/fonts/corpid/glyphicons-halflings-regular.woff") format("woff"),
  url("../resources/fonts/corpid/glyphicons-halflings-regular.ttf") format("truetype"),
  url("../resources/fonts/corpid/glyphicons-halflings-regular.svg#glyphicons-halflings-regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family:"icomoon";
  src:url("../resources/fonts/icomoon/icomoon.eot?#iefix");
  src:url("../resources/fonts/icomoon/icomoon.eot?#iefix") format("eot"),
  url("../resources/fonts/icomoon/icomoon.woff") format("woff"),
  url("../resources/fonts/icomoon/icomoon.ttf") format("truetype"),
  url("../resources/fonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.2;
  text-decoration: none;
  text-decoration: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -webkit-baseline-middle;
  padding: 5px;
}
