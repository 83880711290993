@import "mixins.less";

.text-color-white {
   color: #ffffff;
 }

.wrapper-pri {
  h1 {
    font-size: 34px !important;
    color: @brand-primary;
  }

  h2 {
    font-size: 22px !important;
    color: @brand-primary;
  }

  .wrapper-pri-video.flex-video.widescreen {
    padding-bottom: 53.7%;
    margin-top: 20px;
  }

  .row-footer {
    margin-top: -20px;
  }
}

  .btn-cta-primary {
    .btn-generic;
    background-color: @brand-primary;
  }

  .btn-cta-secondary {
    .btn-generic;
    background-color: @bg-color-secondary-blue;
  }

//Banner

.wrapper-pri-masthead {
  padding-top: 20px;
  padding-bottom: 20px;
  background-position: center top !important;
  height: 300px;

  h1 {
    color: #f9af00;
  }

  h4 {
   color: @brand-primary !important;
   width:70%;
   margin-left: auto;
   margin-right: auto;
   margin-top: 20px;
   line-height: 22px;
  }
}

.wrapper-pri-masthead-blue {
  background-color: #572381 !important;
}

//Breadcrumbs

.wrapper-pri {
  .crumbcontainer {
    display: block;
  }

  .breadcrumb {
    background-color: transparent;
    padding-left: 0;

    li {
      a {
        color: @brand-primary;
      }
    }

    .active {
      color: #3b3b3b;
    }
  }

  .breadcrumb > li+li:before {
    color: #3b3b3b;
  }

}

//CTA

.wrapper-pri-cta {
  background-color: #d6cfde;
  padding-bottom: 10px;
  .full-width;

  h3 {
    margin-bottom: 0;
  }

  .pri-call-us {
    background-image: url('/content/dam/plum/images/icons/icon-phone.png');
    background-repeat: no-repeat;
    background-position: 0 15px;
    padding-left: 50px;
  }

  .pri-chat-with-us {
    background-image: url('/content/dam/plum/images/icons/icon-chat.png');
    background-repeat: no-repeat;
    background-position: 0 15px;
    padding-left: 50px;
  }
}

//PRI Body Content

.wrapper-pri-body-content {
  padding-top: 20px;
  padding-bottom: 30px;
}

.wrapper-income-paths {
  background-color: #f3f4f6;
  padding-top: 40px;

  .income-paths-content {
    background-color: #ffffff;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    padding: 20px 0;

    h3 {
      color: @brand-primary;
    }

    .text-image {
      border-right: 1px solid #f7f5f8;
      height: 220px;

      .btn-cta,
      .btn-cta-primary,
      .btn-cta-secondary {
        position: absolute;
        bottom: 0;
        left: 35%;
      }
    }
  }

  .cultivator-content {
    .text-image {
      padding-right: 20px;
    }
  }

  .self-select-content  {
    .text-image {
      border-right: 0;
      padding-left: 20px;
    }
  }
}

.wrapper-pri-content-box {
  h3 {
    color: @brand-primary;
  }
}

// PRI Strategy pages

  .wrapper-pri-strategy-intro {
    padding-top: 20px;
    padding-bottom: 20px;
    .full-width;
  }

  .wrapper-investment-pots {
    margin-top: 20px;
    margin-bottom: 40px;

    h3 {
      color: @brand-primary;
      margin-top: 40px;
    }
  }

  .wrapper-asset-allocation {
    margin-top: 20px;
    background-color: #d6cfde;
    padding-top: 40px;
    padding-bottom: 40px;

    h3 {
     margin-top: 40px;
    }

    &:after {
      content: '';
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #d6cfde;
      position: relative;
      top: 60px;
      left: 47%;
    }
  }

  .wrapper-balance-invested {
    padding-top: 40px;
    background-color: #f7f5f8;
    padding-bottom: 20px;
  }

  .wrapper-asset-allocation-content {
    background-color: #eeecef;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;
  }

  // Media Queries

  @media screen and (min-width: 768px) {
    .wrapper-pri-masthead {
      h1 + p {
        width: 55%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .wrapper-investment-pots {
      .text-image {
        width: 73%;
      }
    }

    .wrapper-asset-allocation {
      .text-image {
        width: 70%;
      }
    }

    .wrapper-asset-allocation-content {
      .text-image {
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
      }
    }
  }

@media screen and (min-width: 768px) and (max-width:991px) {
  .wrapper-pri-masthead {
    h1 + p {
      width: 65%;
    }
  }
}

  @media screen and (max-width: 767px) {
    .wrapper-pri {
      h1 {
        font-size: 24px !important;
      }
    }

    .wrapper-pri-masthead {
      background-image: none;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }

    .wrapper-income-paths-banner {
      background-color: #f3f4f6 !important;
    }

    .wrapper-pri-body-content {
      padding-right: 15px;
      padding-left: 15px;

      h2 {
       width: 80%;
      }
    }

    .wrapper-income-paths {
      .income-paths-content {
        .text-image {
          border: 0;
        }
      }
    }

    .wrapper-investment-pots,
    .wrapper-asset-allocation,
    .wrapper-asset-allocation-content {
      text-align: center;
    }
 }
