.wrapper-top-nav {
    .global_search_form {
        .glyphicon-search:before {
            display: none;
        }
    }
}

.navbar-nav li {
    font-size: 17px;
    font-family: @font-family-light;
    line-height: 26px;

    a[aria-expanded="false"] {
        color: @primary-black !important;
        padding: 20px 20px 0 10px;
        height: 60px;

        .caret {
            display: none;
        }

        &:after {
            content: "\f078";
            display: inline-block;
            width: auto;
            font-family: "Font Awesome Pro Regular";
            padding-left: 8px;
            font-size: 12px;
        }

        &:hover {
            background-color: transparent;
            color: @brand-primary !important;

            .caret {
                display: none;
            }

            &:after {
                content: "\f077";
                display: inline-block;
                width: auto;
                font-family: "Font Awesome Pro Regular";
                padding-left: 8px;
                font-size: 12px;
                color: @link-color;
            }
        }
    }

    a[aria-expanded="true"] {
        padding: 20px 20px 0 10px;
        height: 60px;

        .caret {
            display: none;
        }

        &:after {
            content: "\f077";
            display: inline-block;
            width: auto;
            font-family: "Font Awesome Pro Regular";
            padding-left: 8px;
            font-size: 12px;
            color: @brand-primary; 
        }
    }

    &.current a {
        color: @link-color;
    }

    &.open a,
    &.open a:hover,
    &.open a:focus {
        background-color: transparent !important;
        color: @brand-primary !important;
    }
}

.megamenu-primary-nav {
    color: @primary-black;
    font-family: @font-family-light;

    p {
        font-size: @font-size-body;
        line-height: 24px;
    }

    h2 {
        font-size: @font-size-small !important;
        line-height: 28px;
        margin-bottom: 10px !important;
    }

    .megamenu-row {
        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .fa-chevron-right-regular {
            font-size: 12px;
            display: inline-block;
            padding-left: 8px;
        }
    }

    #megamenu-col-2 {
        p {
            margin-bottom: 10px;

            a {
                padding-top: 0;
            }
        }
    }

    #megamenu-col-4 {
        h2 {
            font-family: @font-family-heavy;
        }

        .border-btn-primary {
            margin-top: 10px;
            font-size: @font-size-small;
        }
    }
}

@media screen and (min-width: 768px) {
    .global_search_form {
        padding-top: 30px;
    }

    .search-link {
        display: none;
    }

    .form-control {
        font-size: 16px;
        color: @text-color;
        border: 1px solid #e2e6e8;
        box-shadow: none;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer;

        &::placeholder {
            color: #4c626c;
            opacity: 0.5;
        }

        &:focus,
        &:hover {
            border-color: @brand-primary;
        }
    }

    #search-icon:after {
        content: "\f002";
        display: block;
        width: auto;
        font-family: "Font Awesome Pro Regular";
        color: @brand-primary;
        position: absolute;
        right: 15px;
        bottom: -3px;
        opacity: 1;
    }
}

@media screen and (min-width: 992px) {
    .wrapper-megamenu-nav {
        border-bottom: 1px solid #e2e6e8;
    }
    
    .wrapper-top-nav {
        .nav-tabs li {
            a {
                padding: 35px 30px 35px 0 !important;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: @brand-primary;
                }
            }

            a#nav_search {
                display: none;
            }

            &:last-child {
                a {
                    padding-right: 0 !important;
                }
            }
        }
    }

   .navbar-nav li {
        a[aria-expanded="false"],
        a[aria-expanded="true"] {
            padding: 20px 35px 0 10px;
        }
    }

    .megamenu-primary-nav {
       #megamenu-col-1 {
           width: 19%;
       }

        #megamenu-col-2 {
            border-left: 1px solid #e2e6e8;
            padding-left: 30px;
            width: 27%;
        }

        #megamenu-col-3,
        #megamenu-col-4 {
            width: 27%;
        }
   }
    
    .wrapper-top-nav {
        height: 90px;

        .nav-tabs {
            border: 0;
            float: right;

            li a {
                border: 0;
                border-radius: 0;
                background-color: transparent;
                color: @primary-black;
                font-family: @font-family-light;
                padding: 35px 15px 35px 0;
                font-size: @font-size-body;
            }
        }

        .btn-primary {
            width: 112px;
            height: 50px;
            border-radius: 0;
            margin-top: 20px;
            font-family: @font-family-bold;
            font-size: @font-size-small;
            line-height: 24px;
            margin-left: 15px;
        }
    }
    
    .wrapper-primary-nav {
        height: 60px;

        .container-fluid,
        .container {
            padding: 0;
        }
    
        .navbar-default {
            background-color: transparent;
            border: 0;
        }
    
        .navbar-nav li {
            a {
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    
        .nav {
            li {
                position: static;
            }
        }
    
        .dropdown-menu {
            padding: 0 9999px;
            margin: 0 -9999px;
            padding-top: 60px;
            padding-bottom: 50px;
            box-shadow: none;
            border: 0;
            border-bottom: 4px solid @brand-primary;
    
            h2,
            h3 {
                margin-top: 0;
            }
    
            li {
                p {
                    margin-bottom: 20px;
                }
                a {
                    padding-left: 0;
                    color: @link-color;
                    font-size: @font-size-body;
    
                    &:after,
                    &:hover:after {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .navbar-nav li {
        a[aria-expanded="false"],
        a[aria-expanded="true"] {
            padding: 20px 40px 0 10px;
        }
    }

    .wrapper-top-nav {
        .nav-tabs li {
            a {
                padding: 35px 30px 35px 0 !important;
            }

            &:last-child {
                a {
                    padding-right: 15px !important;
                }
            }
        }

        .form-group {
            width: 100%;
        }

        .global_search_field {
            width: 95%;
        }
    }
}

@media screen and (max-width: 991px) {
    .wrapper-top-nav {
        height: 50px;

        .logo {
            img {
                height: 100%;
            }
        }

        .btn-primary {
            padding: 0;
            background-color: transparent;
            border: 0;
            color: @brand-primary;
            font-size: @font-size-body;
            line-height: 24px;
            font-family: @font-family-heavy;
            margin-top: 10px;
            text-decoration: underline;
    
            &:hover {
                text-decoration: none;
            }
        }

        .search-box {
            display: none;
        }
    }
    
    .nav-mobile-header {
        width: 50px;
        min-height: 1px;
        height: 100%;
        float: right;
        background-color: @white;
        position: relative;

        .mobile-menu {
            position: absolute;
            top: -50px;
            right: 0;
            margin-right: 5px !important;
            width: 50px;
            height: 50px;
        }

        .navbar-toggle {
            float: none !important;
            padding: 0;
            border-radius: 0;
            border: 0;
            margin-top: 0;
            margin-bottom: 0;
            background-color: transparent !important;
            margin-right: 0 !important;
    
            #icon-toggle-mobile-header {
                width: 20px;
                height: 20px;
                display: block;
                position: relative;
    
                &:after {
                    content: "\f0c9";
                    font-family: "Font Awesome Pro Solid";
                    color: #161818;
                    position: absolute;
                    left: 3px;
                    top: 10px;
                    font-size: @font-size-small;
                    line-height: 28px;
    
                }
            }
        }
    }

    .wrapper-primary-nav {
        .navbar-toggle {
            float: right !important;
            padding: 0;
            border-radius: 0;
            border: 0;
            margin-top: 10px;
            margin-bottom: 0;
            background-color: transparent !important;
            margin-right: 15px !important;

            #icon-toggle-mobile-nav {
                width: 20px;
                height: 20px;
                display: block;
                position: relative;
        
                &:after {
                    content: "\f00d";
                    font-family: "Font Awesome Pro Solid";
                    color: #161818;
                    position: absolute;
                    left: 3px;
                    font-size: @font-size-body;
                    line-height: 24px;
                }
            }
        }
    }

    .navbar-offcanvas {
        left: unset !important;
        right: -100%;
        background-color: @white;
        width: 100% !important;
        height: 100% !important;
        transition: all 1s ease !important;

        .navbar-header {
            background-color: @soft-grey;
        }

        .megamenu-primary-nav {
            margin-bottom: 0;
            background-color: @soft-grey;
            margin-top: 0;
        }

        .navbar-nav {
            font-size: @font-size-little !important;
            line-height: 26px;

            li {
                width: 100%;

                a[aria-expanded="false"] {
                    margin: 0;
                    padding: 18px 10px 20px 10px !important;
                    line-height: 26px !important;
                    color: @primary-black;
                    height: auto;
                    border-bottom: 1px solid #e2e6e8;
                }

                a[aria-expanded="true"] {
                    margin: 0;
                    padding: 18px 10px 20px 10px !important;
                    line-height: 26px !important;
                    color: @brand-primary !important;
                    height: auto;
                    background-color: transparent;
                    border: 0;

                    &:after {
                        content: "\f077";
                        display: inline-block;
                        width: auto;
                        font-family: "Font Awesome Pro Regular";
                        padding-left: 5px;
                        font-size: 14px;
                        color: @link-color;
                    }

                    &:focus {
                        background-color: transparent;
                    }

                    .caret {
                        display: none;
                    }
                }
            }
        }

        &.offcanvas-transform.in {
            transform: translate3d(-100%, 0, 0) !important;
        }
    }

    #topnav-mobile .nav-tabs {
        background-color: @white;
        border-bottom: 0;

        li {
            width: 100%;
            border-bottom: 1px solid #e2e6e8;

            &:last-child {
                border-bottom: 0;
            }

            a {
                font-size: @font-size-body;
                line-height: 24px;
                color: @primary-black;
                padding: 18px 10px 20px 10px;
            }
        }
    }

    .megamenu-col {
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;

        a {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin: 0 !important;
        }
    }

    #megamenu-col-1 {
        h2:first-of-type {
            display: none;
        }

        p:last-child {
            border-bottom: 1px solid #e2e6e8;
            padding-bottom: 20px;
        }
    }

    #megamenu-col-4 {
        border-bottom: 1px solid #e2e6e8;
        padding-bottom: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .visible-xs,
    .navbar-toggle {
        display: block !important;
    }

    .wrapper-top-nav {
        height: 60px;

        .search-box {
            display: block;
            float: right;
        }

        .btn-primary {
            margin-top: 15px;
        }
    }

    .global_search_form {
        padding-top: 10px;
    }

    .navbar-header {
        width: 100%;
    }

    .nav-mobile-header {
        .navbar-toggle {
            #icon-toggle-mobile-header {
                &:after {
                    top: 5px;
                }
            }
        }
    }

    .navbar-offcanvas {
        position: fixed !important;
        max-width: 100%;
        top: 0px;
        z-index: 999;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-transition: all 0.15s ease-in;
        -moz-transition: all 0.15s ease-in;
        -o-transition: all 0.15s ease-in;
        transition: all 0.15s ease-in;

        .megamenu-primary-nav {
            margin-left: -15px;
            margin-right: -15px;
        }

        .dropdown-menu {
            overflow: hidden;
            padding: 0;
            transition: all 0.15 ease-in;
        }
          
        .dropdown-menu.shown {
            height: auto;
        }
          
        .open .dropdown-menu {
            position: static;
            float: none;
            width: 100%;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            box-shadow:  none !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .wrapper-top-nav {
        height: 60px;

        .search-link {
            padding-top: 5px;

            p {
                margin-bottom: 0;
            }
        }
    }

    .wrapper-megamenu-nav {
        background-color: @white;
    }

    .nav-mobile-header {
        .mobile-menu {
            top: -62px;
        }
    }
}