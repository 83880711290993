.container {
  max-width:1024px;
}

.media, .media-body {
  overflow: hidden;
  zoom: 1;
}

.media-left, .media > .pull-left {
  padding-right: 0px !important;
}


.media-left, .media-right, .media-body {
  display: block;
}

body {
  background-color:@body-bg;
  font-size: @font-size-body;
  color:@text-color;
  overflow-y: scroll;
  overflow-x: hidden !important;
  line-height:24px;
  font-family:@font-family-plum;
}

h1 {
  font-size:18px !important;
  font-family: 'CorpidBold';

}

h2, h3 {
  font-size:17px !important;
  font-family: 'CorpidBold' ;
}

h4 {
     font-size: 15px;
     font-weight: bold;
}

h4,h5,h6 {
     font-size: 14px;
}

h6 a:hover {text-decoration:underline !important}

.font-size-regular {
  font-size: 20px !important;
}

.font-size-small {
  font-size: 18px !important;
}

.font-size-body-text {
  font-size: 16px !important;
}

.font-size-tiny {
  font-size: 14px;
}

.text-corpid-heavy {
  font-family: @font-family-heavy;
}

.no-margin {
  margin:0;
}

.marginbottom20 {
  margin-bottom:20px;
}

.marginbottom40 {
  margin-bottom: 40px;
}

.margintop10 {
  margin-top:10px;
}

.margintop20 {
  margin-top:20px !important;
}

.bg-blue {
  background-color:@brand-primary;
  a {
    &:hover,
    &:focus {
      color: @white;
    }
  }
}

.bg-brand-primary {
  background-color: @brand-primary;
}

.bg-white {
  background-color:@background-color-white;
}

.bg-secondary-blue {
  background-color:@bg-color-secondary-blue;
}

.bg-f2f4f6 {
  background-color: #f2f4f6;
}

.no-padding {
  padding:0;
}

.padding20 {
  padding:20px;
}

.padding15 {
  padding:15px;
}

.padding10 {
     padding: 20px;
}

.no-bdr {
     border: medium none !important;
}

.text-white {
  color:#ffffff;
}

hr {
  border-top:1px solid rgba(199, 199, 199, 0.4);
}

a, a:hover {
  outline:none !important;
  color:#572381;
}

a:focus {
  color:#572381;
  outline: -webkit-focus-ring-color auto 5px;
}


a:hover, h6 a:hover {text-decoration: underline;}


.text-blue {
  color:#527d97;
}

.text-purple {
  color: @primary-purple;
}

.img-responsive img {
  width:100%;
}

.img-responsive .pull-left {
  margin-right:0;
}

.font-size-huge {
  font-size: @font-size-huge !important;
}

.font-size-big {
  font-size: @font-size-big !important;
}

.font-size-large {
  font-size: @font-size-large !important;
}

.font-size-medium {
  font-size: @font-size-medium !important;
}

.font-size-regular {
  font-size: @font-size-regular !important;
}

.font-size-small {
  font-size: @font-size-small !important;
  line-height: 28px;
}

.font-size-little {
  font-size: @font-size-little !important;
}

.font-size-body {
  font-size: @font-size-body !important;
}

.font-size-tiniest {
  font-size: @font-size-tiniest;
}

.font-family-light,
.font-family-light * {
  font-family: @font-family-light;
}

.font-family-regular,
.font-family-regular *,
.text-corpid-regular {
  font-family: @font-family-regular !important;
}

.font-family-bold,
.font-family-bold * {
  @font-family: @font-family-bold;
}

.font-family-heavy
.font-family-heavy * {
  @font-family: @font-family-heavy;
}

.overview-bg-image {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 130px;
  width: 277px;
  margin: 0px 0 0 20px!important;
}

.border-btn-primary {
  .border-btn-generic(@brand-primary, @brand-primary);
}

.border-btn-white {
  .border-btn-generic(@white, @white);
}

//Plum updated font sizes 

.plum-uplift {
  color: @primary-black;
  font-size: @font-size-body;
  line-height: 24px;
  font-family: @font-family-light;

  p {
      font-size: @font-size-body;
      color: @primary-black;
      line-height: 24px;
      margin: 0 0 15px 0;
  }

  h1 {
      font-size: @font-size-huge;
      line-height: 50px;
  }

  h2 {
      font-size: @font-size-large;
      line-height: 42px;
  }

  h3 {
      font-size: @font-size-regular;
      line-height: 32px;
  }

  h4 {
      font-size: @font-size-small;
      line-height: 28px;
  }
}

//Accordions
.panel {
  border: medium none;
  box-shadow: none;
  margin-top: 20px;
  border-radius:none !important;
}

.panel a {
     text-decoration: none;
}

.panel .list-item-text a:hover {
     color: @brand-primary;
}

.panel-heading {
  background-color: #E9EBEC !important;
  border-radius:none !important;
  border-top-left-radius:0 !important;
  border-top-right-radius:0 !important;
}

.panel-heading .panel-title a.collapsed {
  background-image: url("/content/dam/plum/images/icons/arrows_new.png");
  background-position: right 6px ! important;
  background-repeat: no-repeat;
  color: #3b3b3b ! important;
  display: block;
  padding-right: 25px;
  text-decoration: none;
}

.panel-title a {

  outline: medium none;
}

.panel .pull-left img {
     padding: 30px 0px 10px 10px;
}

.panel .list-item-text h4 {
     margin-bottom: 0px ! important;
}

.panel-default .panel-title a {
  background-image: url("/content/dam/plum/images/icons/arrows_new.png");
  background-position: right -188px ! important;
  background-repeat: no-repeat;
  color: #3b3b3b !important;
  display: block;
  outline: 0px none;
  padding-right: 25px;
  text-decoration: none;
}

.panel .list-item-text {
  border:1px solid #dddddd;
  border-top: medium none;
  border-bottom-left-radius:3px;
  border-bottom-right-radius:3px;
  padding:15px !important;
}

.list-item-text table.table td {
  border: 0px none;
  padding: 0px 10px 0px 0px;
  vertical-align: middle;
}

.list-item-text ul {
  margin-bottom: 10px;
  padding-left:20px;
}

.panel .list-item-text ul li {
  list-style-type: disc;
}

.list-item-text img {
  margin-right:10px;
}

//Breadcrumb

.breadcrumb {
  background-color: #527d96;
  border-radius: 0px;
  list-style: none outside none;
  margin-bottom: 0px;
  padding: 8px 10px;
}

.breadcrumb li a {
  color:#c6d4dd;
}

.breadcrumb > .active {
  color: #eeecdb;
}

br.crumbend {
  display: none;
}

//Left hand navigation - All pages

.left-hand-nav {
  background-color: #fff !important;
  background-image: none;
  background-position: center top;
  background-repeat: no-repeat;
}

.list-group {
  border-bottom: 1px solid #FFFFFF;
  margin: 5px 0px ! important;
}

.lt-ie9 .list-group {
  background-color:transparent !important;
}

.list-group-item {
  background-color: rgba(0, 0, 0, 0) ! important;
  border: 0px none ! important;
  color: @brand-primary ! important;
  outline: medium none;
  padding: 5px 5px 2px 15px ! important;
}

.lt-ie9 .list-group-item {
  background-color:transparent !important;
}

.list-group-item:first-child {
  font-weight: bold ! important;
  padding-left: 7px ! important;
}

.list-group-item:last-child {
  padding-bottom: 10px ! important;
}

a.list-group-item:hover, a.list-group-item:focus {
  outline: medium none ! important;
  text-decoration: underline ! important;
}

.list-group a.active {
  color: #3b3b3b ! important;
  font-weight: normal;
}

.list-group-products, .list-group-contact-us, .list-group-latest-news, .list-group-help, .list-group-introducing-plum, .list-group-fin-advice {
  margin-top: 77px ! important;
}

.list-group-products .list-group-item:first-child, .list-group-contact-us .list-group-item:first-child, .list-group-latest-news .list-group-item:first-child, .list-group-help .list-group-item:first-child, .list-group-introducing-plum .list-group-item:first-child, .list-group-fin-advice .list-group-item:first-child {
  border-radius: 0px ! important;
  border-top: 1px solid #ffffff ! important;
}


//Homepage

.carousel-control {
  display:none !important;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  display:none;
}

.banner-text {
  color:#ffffff;
  font-weight:bold;
  line-height:26px;
  padding:0px 15px 7px 15px;
  float:right;
}

.seminar {
  background-image: url("/content/dam/plum/images/backgrounds/seminar_box_bg.png");
  margin-bottom:20px;
  min-height:290px;
}

.seminar img {
  height: 100%;
  padding-left: 11px;
  padding-top:10px;
  width: 100%;
  position:relative;
  top:10px;
}

.seminar h1 {
  color: @text-color;
}

.seminar .register-now-box {
  background-color: #ffffff;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 20px;
  padding: 20px 10px;
}

.register-now-box h1 {
  margin-bottom: 25px;
  margin-top: 0px;
}

.register-now-box a {
  background-color: #ac2641;
  color: #ffffff;
  padding: 10px 28px;
  border-radius:5px;
}

.seminar .content-title a, .market-watch .content-title a {
  color:#ffffff !important;
  outline:none;
}

.seminar .content-title a:hover, .market-watch .content-title a:hover {
  color:#ffffff;
  text-decoration:none;
}

.market-watch {
  padding-right: 0px;
  margin-bottom:20px;
}

.seminar h4, .market-watch h4 {
  margin-left: 20px;
  color:#ffffff;
}

.market-watch img {
  width:100%;
}

.market-watch-content {
  margin: 0px 20px;
  min-height:278px !important;
}

.market-watch-content h4 {
  margin-left: 0px;
}

.market-watch .text-image a {
  color:@text-color;
  outline:none;
}

.market-watch .text-image p:last-child {
  margin-top:20px;
}

.market-watch .text-image p:last-child a {
  color: @link-color;
}

.market-watch .text-image a:hover, .market-watch .text-image p:last-child a:hover {
  text-decoration:none;
  color:#b7b3b4;
}

.home-content-boxes {
  border-right: 1px solid #ffffff;
  padding: 25px 10px 0 10px;
  min-height:125px;
  background-color: #fff;
}

.home-content-boxes .text-image p {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 0 ! important;
  width: 130px;
  margin-right:0 !important;
}

.home-content-boxes .text-image a {
  color: #3b3b3b;
  font-weight: bold;
}

.home-content-boxes .pull-left {
  margin-left:10px;
}

.plum-super {
  background-image: url("/content/dam/plum/images/icons/icon_chart.png");
  background-position: 10px 20px;
  background-repeat: no-repeat;
  padding-left: 80px;
  padding-top:25px;
}

.home-financial-advice {
  background-image: url("/content/dam/plum/images/icons/icon_advice.png");
  background-position: 10px 20px;
  background-repeat: no-repeat;
  padding-left: 80px;
  padding-top:25px;
}

.lost-super {
  background-image: url("/content/dam/plum/images/icons/icon_question.png");
  background-position: 10px 20px;
  background-repeat: no-repeat;
  padding-left: 80px;
  padding-top:25px;
}

.personal-plan {
  background-image: url("/content/dam/plum/images/icons/icon_people.png");
  background-position: 10px 20px;
  background-repeat: no-repeat;
  padding-left: 80px;
  padding-top:25px;
}

.home-accordion .list-item-text {
  border:none;
  h3 {
    margin: 0 0 10px;
    span.panel-title {
      font-family: @font-family-light;
      font-size: 17px;
      font-weight: 700;
    }
  }
}

.home-accordion table.table td {
     vertical-align: top ! important;
}

.home-accordion table {
  border: medium none;
}

.home-accordion table td {
  border: medium none;
}

.home-accordion table td a {
  outline: medium none;
  text-decoration: underline;
  line-height:21px;
}

.home-accordion table td a:hover {
  color: @link-color;
  text-decoration: none;
}

.icon-mysuper, .icon-fund-choice {
  float: left;
  margin-right: 30px;
  vertical-align: top;
  width: 300px;
}

.icon-leaving-employer {
  width: 260px;
  float: left;
  margin-right: 30px;
  vertical-align: top;
}

.icon-mysuper a, .icon-fund-choice a, .icon-leaving-employer a {
  line-height: 20px ! important;
}

.icon-mysuper {
  background-image: url("/content/dam/plum/Icon_plumMysuper.gif");
  background-position: 0px center;
  background-repeat: no-repeat;
  padding-bottom: 0px;
  padding-left: 60px;
}

.icon-fund-choice {
  background-image: url("/content/dam/plum/Icon_ChoiceofFund2.gif");
  background-position: 0px center;
  background-repeat: no-repeat;
  padding-bottom: 0px;
  padding-left: 60px;
}

.icon-leaving-employer {
  background-image: url("/content/dam/plum/Icon_LeavingEmployer.gif");
  background-position: 0px center;
  background-repeat: no-repeat;
  padding-bottom: 0px;
  padding-left: 60px;
}

.banner-bottom {
  background-image: url("/content/dam/plum/images/backgrounds/home-banner-bottom.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.banner-bottom h1, .banner-bottom h4 {
  color: #000000;
  line-height: 22px;
  margin-left: 20px;
  width: 420px;
}

.banner-bottom h1 {
  font-size: 26px ! important;
  margin-top: 20px;
}

.banner-bottom h4 {
  font-size: 18px ! important;
  font-weight: normal;
  margin-bottom: 10px;
}

.banner-bottom a {
  background-color: #C9CB00;
  color: #3b3b3b;
  font-size: 14px;
  font-weight: bold;
  line-height: 42px;
  margin-left: 20px;
  padding: 8px 15px;
}

//Product and services

.content-box {
     background-color: #ffffff;
     padding: 0px;
}

.content-box img {
  margin:20px 0 0 20px;
}

.content-box a {
  outline:none;
}

.content-box ul {
  padding-left:0px;
}

.content-box ul li {
  list-style-type: none;
  line-height:32px;
}

.product-services-box {
  min-height:710px;
}

.content-box a:hover {
  text-decoration:none;
  color: @link-color;
  outline:none;
}

.content-box a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.content-title {
     background-color:#fff;
}

.content-title h2 {
     color: #572381;
     line-height: 30px;
     margin: 0px;
     padding: 5px 0px 5px 10px;
     font-size:22px !important;
     margin: 15px 0 10px 0;
}

.products {
  background-image: url("/content/dam/plum/images/content/products_tile.jpg");
}

.services {
  background-image: url("/content/dam/plum/images/content/services_tile.jpg");
}

.considering-plum {
  background-image:url("/content/dam/plum/images/content/consider_tile.jpg");
}

//Right Column - Products & services

.right-col-content-box {
  background-color: #572381;
  margin: 10px 5px 10px 0 !important;
  font-size:12px !important;
}

.right-col-content-box h2 {
  margin: 5px;
}

.right-col-content-box ul {
  margin:0 5px 0 0px;
  padding:0 5px 10px 5px;
}

.right-col-content-box ul li {
  list-style-type:none;
  background-image:url("/content/dam/plum/images/icons/arrow-right-white.png");
  background-repeat:no-repeat;
  background-position:2px 5px;
  padding-left:15px;
  padding-bottom:5px;
}

.right-col-content-box ul li a {
  font-weight:bold;
}

.right-col-content-box ul li a:hover {
  color:#ffffff;
  text-decoration:underline;
}

.right-col-content-box ul li a, .right-col-content-box ul li a:hover {
  outline:none;
}

.right-col-content-box ul li a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.right-col-content-box {
  h2, a, ul li {
   color: #ffffff;
  }
}

.right-col .pull-left {
	margin-right:0 !important;
}

.right-col img {
	margin:0 5px 10px 0;
	width:97%;
}

//Forms and publications

.content-header {
  margin-bottom: 10px;
}

.content-header h1 {
  color: #ffffff;
  margin: 0px 0px 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-top: 10px;
}

.forms-publications ul {
  padding-left: 0px;
}

.forms-publications ul li {
  list-style-type: none;
}

.forms-publications ul li a {
  color: @brand-primary;
  font-weight: 700;
  text-decoration: none;
}

.forms-publications ul li a:hover {
  color: #b7b3b4;
}

.form-accordions .list-item-text h4 {
  margin-bottom:7px !important;
}

.form-accordions .list-item-text p {
  margin-bottom:15px;
}

.form-accordions .panel-heading {
    background-color: #F2F4F6 !important;
}
.form-accordions .panel-heading .panel-title a.collapsed {
    color: #3b3b3b !important;
}
.form-accordions .panel-title {
  font-size: 14px !important;
  h4 {
    font-family: @font-family-light!important;
    font-size: 17px!important;
    font-weight: 700!important;
  }
}

.icon-pdf {
  background-image:url("/content/dam/plum/images/icons/icon_pdf.jpg");
  background-repeat:no-repeat;
  background-position:0 0;
  padding-left:25px;
}

// Education
.education {
  padding-left:15px;
  margin:20px 0;
  padding-right:10px;
}

.education-tabs .nav-tabs {
  border-bottom: 0px none;
}

.education-tabs .nav-tabs li {
  width: 127px;
}

.education-tabs .nav-tabs li a {
  background-repeat: no-repeat;
  background-size: 127px 114px;
  border-radius: 0px ! important;
  border: 0px none;
  color: transparent !important;
  height: 114px;
  margin: 0px 2px 0px 0px ! important;
  outline: medium none;
  padding: 0px ! important;
}

.lt-ie9 .education-tabs .nav-tabs li a {
  font-size:0 !important;
}

.lt-ie9 .education-tabs .nav-tabs li.active a {
  font-size:0 !important;
}

.education-tabs .nav-tabs li a:hover {
  color:transparent;
  border: 0px none;
}

.education-tabs .nav-tabs li a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.education-tabs .nav-tabs li a#nav_starting-out {
  background-image: url("/content/dam/plum/images/backgrounds/Starting-out.png");

}

.education-tabs .nav-tabs li.active a#nav_starting-out, .education-tabs .nav-tabs li a#nav_starting-out:hover {
  background-image: url("/content/dam/plum/images/backgrounds/starting_out_active.png");

}

.education-tabs .nav-tabs li a#nav_super-foundations {
  background-image: url("/content/dam/plum/images/backgrounds/Super-foundations.png");

}

.education-tabs .nav-tabs li.active a#nav_super-foundations, .education-tabs .nav-tabs li a#nav_super-foundations:hover {
  background-image: url("/content/dam/plum/images/backgrounds/super_foundations_active.png");

}

.education-tabs .nav-tabs li a#nav_building-your-super {
  background-image: url("/content/dam/plum/images/backgrounds/Building-your-Super.png");

}

.education-tabs .nav-tabs li.active a#nav_building-your-super, .education-tabs .nav-tabs li a#nav_building-your-super:hover {
  background-image: url("/content/dam/plum/images/backgrounds/build_your_super_active.png");

}

.education-tabs .nav-tabs li a#nav_preparing-to-retire {
  background-image: url("/content/dam/plum/images/backgrounds/Preparing-to-Retire.png");

}

.education-tabs .nav-tabs li.active a#nav_preparing-to-retire, .education-tabs .nav-tabs li a#nav_preparing-to-retire:hover {
  background-image: url("/content/dam/plum/images/backgrounds/prepare_to_retire_active.png");

}

.education-tabs .nav-tabs li a#nav_retired {
  background-image: url("/content/dam/plum/images/backgrounds/Retired.png");

}

.education-tabs .nav-tabs li.active a#nav_retired, .education-tabs .nav-tabs li a#nav_retired:hover {
  background-image: url("/content/dam/plum/images/backgrounds/retired_active.png");
}

.calc-super p {
  background-color: #fff;
  color: #3b3b3b;
  font-size: 14px;
  padding: 30px 10px;
  background-image:url("/content/dam/plum/images/icons/calculator_big.png");
  background-repeat:no-repeat;
  background-position:20px 20px;
  padding-left:110px;
}

.calc-super p a {
  color:@brand-primary;
  text-decoration:underline;
  font-weight:bold;
}

.calc-super p a:hover {
  text-decoration:none;
  color:#ffffff !important;
}

.education-accordion-content table td {
  vertical-align:top;
}

.education-accordion-content table td p {
  width:300px;
  background-color: #8da9ba;
  min-height:125px;
  margin-right:10px;
  color:#ffffff;
}

.education-accordion-content table td p a {
  color: #FFFFFF;
  text-decoration:underline;
  font-weight:bold;
}

.education-accordion-content table td p a:hover {
  text-decoration:none;
  color:#ffffff !important;
}

.login, .contact, .fact-sheet {
  background-repeat:no-repeat;
  padding:20px 15px 20px 95px;
  background-size:64px 64px;
  background-position:15px 10px;
  float:left;
  margin-top:0px;
}

.login {
     background-image: url("/content/dam/plum/images/icons/lock_big.png");
}

.contact {
     background-image: url("/content/dam/plum/images/icons/adviser_big.png");
}

.fact-sheet {
     background-image: url("/content/dam/plum/images/icons/file_big.png");
}

.education-right-col .pull-left {
  margin-right:0;
}

.education-right-col .pull-left img {
  margin:0 0 0 0;
  width:100%;
}

.other-topics p {
  border-bottom: 1px solid #c7c7c7;
  border-bottom: 1px solid rgba(199, 199, 199, 0.5);
  padding-bottom:15px;
  margin-bottom:20px;
}

.icon-small-video, .icon-small-calc, .icon-small-forms {
  background-position: 0px 1px;
  background-repeat: no-repeat;
  font-size: 14px;
  padding-left: 25px;
}

.icon-small-video {
  background-image: url("/content/dam/plum/images/icons/video_small.png");
}

.icon-small-calc {
  background-image: url("/content/dam/plum/images/icons/calculator_small.png");
}

.icon-small-forms {
  background-image:url("/content/dam/plum/images/icons/file_small.png");
}

.education-tabs {
  margin-bottom: 10px;
  margin-top: 10px;
}

.edu-starting-out-title h4, .edu-video-thumb a {
  color: @brand-primary;
}

.edu-super-foundations-title h4, .super-foundations-video-thumb a {
  color: #7cafa4;
}

.edu-building-super-title h4, .building-super-video-thumb a {
  color: #8d2331;
}

.edu-preparing-to-retire-title h4, .preparing-to-retire-video-thumb a {
  color: #2a3763;
}

.edu-retired-title h4, .retired-video-thumb a {
  color: #747ca1;
}

.edu-video-thumb {
  padding-right: 10px;
}

.edu-video-thumb img {
  width:100%;
}

.edu-video-thumb p {
  margin-top: 10px ! important;
}

.edu-right-col-content-box {
  background-color: #f8f8f8;
  margin-top: 20px ! important;
  padding: 10px 10px 10px 10px;
  font-size:12px;
  width:295px;
}

//News

.news {
     background-image: url("/content/dam/plum/images/content/news_tile.jpg");
}

.investments {
     background-image: url("/content/dam/plum/images/content/investments_tile.jpg");
}

.news-faq {
     background-image: url("/content/dam/plum/images/content/faq_tile.jpg");
}

.market-watch-col-img img {
  margin-top:0;
}

.news-hub-content-box {
  background-color:@bg-color-secondary-blue;
  color:#ffffff;
}

.news-hub-content-box h3 {
  margin: 0px 0px 10px;
}

.news-hub-content-box a {
  color: #ffffff;
  font-weight: bold;
}

.news-hub-content-box a:hover, .news-hub-content-box a:focus {
  outline: medium none;
  text-decoration: none;
}

.news-hub-content-box p {
     margin-bottom: 0px;
}

.left-hand-nav .nav-pills li.first a#nav_latest-news {
    padding-top:44px !important;
}

.news-hub-content {
  padding:10px 15px 10px 15px;
}

.news-hub-content a {
  font-weight:bold;
}

.headlines-content {
  border-right: 1px solid rgba(136, 133, 128, 0.2);
  padding: 15px;
  background-color: #ffffff;
  min-height: 840px;
}

.lt-ie9 .headlines-content {
  border-right: 1px solid #888580;
  padding: 15px;
  background-color: #ffffff;
  min-height: 840px;
}

.headlines-content p img {
  float: left;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-top:5px;
}

img.inv-news-col-img {
  width:100%;
}

.more-news {
     margin-top: 10px;
}

.more-news-content {
     border: 1px solid #dddddd;
     margin: 10px ! important;
     padding: 10px;
}

.more-news-content h4 {
     margin-top: 0px ! important;
}

.more-news-content p {
  margin-bottom:20px;
}

.faqs-accordion .panel-group {
  margin-bottom:0 !important;
}

.faqs-accordion .panel {
  border-bottom: 1px solid #dddddd;
  border-radius: 0px;
  margin-top: 10px ! important;
  padding-bottom: 5px;
}

.faqs-accordion .panel-heading {
  background-color: rgba(0, 0, 0, 0) ! important;
  background-color:transparent !important;
}

.lt-ie9 .faqs-accordion .panel-heading {
  background-color:transparent !important;
}

.faqs-accordion .panel-heading .panel-title a.collapsed {
  background-image: url("/content/dam/plum/images/icons/arrows_new.png");
  color: #3b3b3b ! important;
  font-size: 14px;
}

.faqs-accordion .panel-heading .panel-title a {
  background-image: url("/content/dam/plum/images/icons/arrows_new.png");
  color: #3b3b3b;
  font-size: 14px;
}

.faqs-accordion .panel .list-item-text {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 0px none;
  padding-bottom: 15px ! important;
}

// Financial advice page

.gradient-blue {
  background-image:none;
}

.support-tools p {
  border-bottom:1px dotted #999999;
  min-height:70px;
  margin-bottom:30px;
}

.fin-adv-content {
  padding:0 20px;
}

.support-tools h2 img {
  margin-right:5px;
}

.support-tools a:hover {
  text-decoration:none;
  color:@link-color !important;
}

.fin-adv-content h2:first-child {
  margin-top: 0px;
}

.fin-advice-banner .pull-left {
  margin-right:0;
}

.fin-advice-banner img {
  width: 100%;
}

//About Plum

.who-is-plum {
  background-image: url("/content/dam/plum/images/content/Introducing_tile.jpg");
}

.media-releases {
  background-image: url("/content/dam/plum/images/content/MediaReleases_tile.jpg");
}

.media-enquiries {
  background-image: url("/content/dam/plum/images/content/MediaCentre_tile.jpg");
}

.media-release-date {
  padding:0 0 0 10px;
}

.abt-plum-leadership h2 {
  margin-top:10px !important
}

.leadership-content {
  border-bottom: 1px dotted #c7c7c7;
  margin-bottom: 20px;
  margin-top:10px;
}

.leadership-content h3 {
  margin-top: 0px;
}

.img-leader-bio img {
  width:100%;
}

.abt-plum-vision h2 {
  margin-top:0px !important;
}

.abt-plum-vision img {
  padding-top:10px;
}

//Contact us

.contact-us-feedback, .contact-us-media {
  margin-bottom:20px;
}

.contact-us-members h3 {
  background-image: url("/content/dam/plum/images/icons/icon_member.png");
  background-repeat: no-repeat;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-top: 10px;
}

.contact-us-employers h3 {
  background-image: url("/content/dam/plum/images/icons/icon_employer.png");
  background-repeat: no-repeat;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-top: 10px;
}

.contact-us-media h3 {
  background-image: url("/content/dam/plum/images/icons/icon_media.png");
  background-repeat: no-repeat;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-top: 10px;
}

.contact-us-feedback h3 {
  background-image: url("/content/dam/plum/images/icons/icon_feedback.png");
  background-repeat: no-repeat;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-top: 10px;
}

.contact-us-accessiblity h3 {
  background-image: url("/content/dam/plum/images/icons/icon-hearing-impaired.png");
  background-repeat: no-repeat;
  padding-bottom: 15px;
  padding-left: 60px;
  padding-top: 10px;
}

.contact-us-content img {
  margin-right: 10px ! important;
}

.contact-us-content h4 {
  margin-bottom: 5px;
}

.contact-us-content p {
  margin-bottom: 15px;
}

.right-col-login-box {
  background-color: #572381;
  font-size: 12px;
  margin: 10px 5px 0px 0px ! important;
  padding: 10px 5px;
}

.right-col-login-box p {
  margin-bottom: 0px;
}

.right-col-login-box h4 {
 background-color: #a92582;
 background-image: url("/content/dam/plum/images/icons/arrow-right-white.png");
 background-position: 25px center;
 background-repeat: no-repeat;
 border-radius: 5px;
 color: #ffffff;
 margin-bottom:0;
 padding: 10px 20px;
 text-align: center;
 width: 90%;
 margin-left:10px;
}

.right-col-login-box h5 {
  font-weight:bold;
}

.right-col-login-box {
  h5, p, .text-white {
    color:#ffffff !important;
  }
}

.forms-bg-img .pull-left {
  margin-right:0 !important;
}

.forms-bg-img {
  background-image: url("/content/dam/plum/images/content/forms_pubs.jpg");
  background-repeat: no-repeat;
  background-size: 203px 130px;
  height: 130px;
  margin-bottom: 0px;
  margin-top:10px !important;
  width:203px;
}

.contact-us-faqs {
 font-size:12px;
}

.forms-bg-img {
  background-image: url("/content/dam/plum/images/content/forms_pubs.jpg");
  background-size: 203px 130px;
  height: 130px;
  background-repeat:no-repeat;
  margin-bottom:0;
}

.icon-small-phone h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_phone.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

.icon-small-globe h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_globe.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

.icon-small-feedback h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_feedback.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

.icon-small-fax h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_fax.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

.icon-small-email h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_email.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

.icon-small-mail h4 {
     background-image: url("/content/dam/plum/images/icons/icon_small_mail.png");
     background-position: 0px 0px;
     background-repeat: no-repeat;
     padding-bottom: 10px;
     padding-left: 40px;
     padding-top: 5px;
}

/* .row-footer .text-image {
  margin: 0px 0px 20px;
} */

//Help

.icon-arrow {
  background-image:url("/content/dam/plum/images/icons/arrow-right-blue.png");
  background-repeat:no-repeat;
  background-position:2px 5px;
  padding-left:15px;
  margin-bottom:5px;
}

.help-content p {
  margin-bottom:20px;
}

//Browsers

.browser-content h2 {
  margin-top:0;
}

.browser-content table {
  border: 0px none;
}

.browser-content table th {
  color: #527D97;
  width: 150px;
  border:0;
  border-bottom:1px dotted #eeecdb !important;
}

.browser-content table th h4 {
  margin-top:0;
  padding:4px 8px;
  margin-right:8px;
}

.browser-content table td {
  border:0;
  border-bottom:1px dotted #eeecdb !important;
  padding:4px 8px;
}

//Sitemap

.sitemap-links {
  border-bottom: 1px dotted #C7C7C7;
  padding-bottom: 10px;
  margin-top:20px !important;
}

.sitemap-links h2 {
  margin-top:0 !important;
  margin-bottom: 0px;
}
.sitemap-links ul{
  padding-left: 20px !important;
  font-size:12px;
}

.sitemap-links a {
  font-weight:bold;
}

.sitemap-links:last-child {
  margin-bottom:20px;
}

//Search

#search, .didyoumeantext {
  background-color: #ffffff;
  padding-bottom: 20px;
  padding-left: 20px;
}

.search-result-container .panel-heading h3 {
  color: #333;
}

ul.pagination li a {
  cursor: pointer ! important;
}

.search-results-list li {
  margin-bottom:20px;
}

// Error pages

.error-page-title h1 {
  color: #ffffff;
  line-height: 30px;
  margin-left: 10px;
  margin-top: 10px;
}

.error-page-content, .error-404, .error-401, .error-403, .error-500, .error-503 {
  margin: 20px 0px ! important;
}

.error-404 p, .error-403 p {
  background-color: #572381;
  background-image: url("/content/dam/plum/images/icons/404.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 80px;
  min-height:80px;
  color:#ffffff;
}

.error-401 p {
  background-color: #8da9ba;
  background-image: url("/content/dam/plum/images/icons/lock_big.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 80px;
  min-height:80px;
  color:#ffffff;
}

.error-500 p, .error-503 p {
  background-color: #8da9ba;
  background-image: url("/content/dam/plum/images/icons/500.png");
  background-position: 10px 20px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 80px;
  min-height:90px;
  color:#ffffff;
}

.error-404 p a, .error-403 p a, .error-401 p a, .error-500 p a, .error-503 p a {
  color: #ffffff;
}


//Responsive styles - (min-width:1024px and max-width:1279lpx)

@media (min-width: 1024px) and (max-width: 1279px) {
  /* 
  .navbar-nav li {
    width: 117px;
    line-height: 20px;
    font-size: 12px;
  }
  .navbar-nav li.current {
    width: 117px ! important;
  }
  .navbar-nav li a#nav_contact-us {
    line-height: 40px;
  }
  .navbar-nav li:last-child {
    width: 118px ! important;
  } */
  .seminar {
    height:315px !important;
  }
  .seminar .pull-left img {
    margin-top:17px !important;
  }
  .register-now-box a {
    padding: 10px 20px;
  }
  .home-content-boxes img {
    width: 55px; height: 55px;
  }
  .home-content-boxes .text-image p {
    width: 135px ! important;
    margin-left:0;
  }
  .education-tabs .nav-tabs li {
    width: 120px;
  }
  .education-accordion-content p {
    width: 280px !important;
  }
  .login, .contact, .fact-sheet {
    background-size: 45px 45px;
    padding-left: 65px;
    background-position: 10px 10px;
    margin-top: 10px;
    padding-top:10px;
  }

  .headlines-content {
    min-height:880px;
  }
  .edu-right-col-content-box {
    width: 283px;
  }

  .forms-bg-img {
    background-size: 190px 122px;
    height: 122px;
  }
}

//Responsive styles - (min-width:768px and max-width:1023px)

@media (min-width: 768px) and (max-width: 1023px) {
  body {
    font-size: 12px;
  }

  .container {
    padding-left:10px;
    padding-right:10px;
  }

  .plum-logo img {
      width:110px;
    }

  .btn-login {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .xs-no-padding {
    padding: 0px;
  }

  .btn-login h4 {
    background-position: 20px center;
    padding: 10px 30px 10px 35px ! important;
    margin-left: 0px ! important;
  }

  .btn-login p a {
    margin-left: 10px;
  }

  /*
  .navbar {
    border-bottom: 0px none;
    border-left: 0px none;
    font-size: 14px;
    height:auto !important;
  }

  .navbar-nav li {
    width: 182px ! important;
    border-right: medium none;
  }

  .navbar-nav li a#nav_financial-advice, .navbar-nav li a#nav_news-information, .navbar-nav li a#nav_news-and-information, .navbar-nav li a#nav_contact-us {
    line-height: 40px;
  } */

  .market-watch img {
    margin-top: 10px;
    margin-left: 10px;
  }

  .market-watch-content {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .seminar {
    min-height: 315px;
  }

  .seminar img {
    display: none;
  }

  .register-now-box {
    width: 260px;
    margin: 20px 0px 0px ! important;
    text-align: center;
  }

  .home-content-boxes .text-image p {
    font-size: 12px;
    margin-left: 5px;
    float: left;
    white-space: normal ! important;
    width: 110px;
  }

  .plum-super, .home-financial-advice, .lost-super, .personal-plan {
    background-size: 45px 45px;
    padding-left: 55px;
    background-position: 5px 30px;
  }

  .list-item-text {
    font-size: 12px;
  }

  .home-accordion table td .icon-mysuper, .home-accordion table td .icon-fund-choice, .home-accordion table td .icon-leaving-employer {
    margin-right: 10px;
    width: 225px;
  }

  .banner-bottom h4 {
    width: 400px;
    padding-right: 20px ! important;
  }

  .overview-bg-image {
    height: 106px;
    width: 225px;
  }

  .row-footer .nav-tabs {
    float: none!important;
  }

  .row-footer .nav-tabs li a {
    padding-left: 3px ! important;
  }

  .row-footer .nav-tabs li:nth-child(1) a{padding-left:0 !important}

  .education-tabs .nav-tabs li {
    width: 90px;
  }

  .education-tabs .nav-tabs li a {
    background-size: 90px 81px; height: 81px;
  }

  .education-accordion-content table td {
    vertical-align: top;
  }

  .education-accordion-content p {
    width: 210px !important;
    margin-top: 0px ! important;
    min-height: 140px ! important;
  }

  .login, .contact, .fact-sheet {
    background-size: 45px 45px;
    padding-left: 65px;
    background-position: 10px 10px;
    margin-top: 10px;
    padding-top:10px;
  }

  .edu-right-col-content-box {
    width: 210px;
  }

  .forms-bg-img {
    background-size: 199px 130px;
    height: 130px;
    width:199px;
  }

  .market-watch-col-img img {
    width:225px;
    height:175px;
    margin-left:10px;
  }

  .headlines-content {
    min-height:950px;
 }

  .market-watch .text-image p:last-child {
    margin-right: 10px;
    }
}

//Responsive styles - xs screens

@media (min-width: 481px) and (max-width: 767px) {
 body {
   font-size: 12px;
 }

  .navbar {
    height: auto ! important;
    font-size: 14px ! important;
  }

  .navbar-collapse {
    min-height:420px;
  }

  .navbar-nav li {
    width: 97%;
  }
  .navbar-nav li a {
    padding: 5px 0px ! important;
    margin: 0px 20px;
    text-align: left;
    line-height:40px !important;
  }

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .top-navigation {
    padding: 0px;
  }

  .btn-login {
    margin: 10px 0px;
  }

  .btn-login h4 {
    float: right;
  }

  .btn-login p a {
    float: right ! important;
    clear: both;
  }

  #global_search_field {
    width: 90%;
  }

  #search-icon {
    top: -22px;
    float: right;
  }

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .seminar, .market-watch {
    width: 500px;
    margin: 10px 0px 10px 40px;
  }

  .market-watch-content {
    min-height: 100px ! important;
  }

  .register-now-box {
    width: 260px;
    margin: 20px auto 0px ! important;
    text-align: center;
  }

  .home-accordion table td {
    float: none;
    margin-bottom: 10px;
    clear: both;
  }

  .product-services-box {
    min-height: 500px ! important;
    margin-bottom: 10px;
  }

  .xs-marginbottom10 {
    margin-bottom: 10px;
  }

  .row-footer {
    font-size: 12px ! important;
  }

  .row-footer .nav-tabs li {
    width: 50%;
  }

  .row-footer .nav-tabs li a {
    padding-bottom: 2px ! important;
    padding-left: 0px ! important;
    line-height: 21px ! important;
    border-right: 0px none ! important;
  }

  .row-footer img {
    margin-right: 5px ! important;
    margin-bottom: 10px;
  }

  .icon-mysuper, .icon-fund-choice, .icon-leaving-employer {
    width: 190px;
    margin-right: 10px ! important;
    vertical-align: top;
  }

  .forms-publications .icon-mysuper, .forms-publications .icon-fund-choice, .forms-publications .icon-leaving-employer {
    width: auto;
    margin-right: 10px ! important;
    margin-bottom:12px;
  }

  .icon-mysuper {
    padding-left: 50px;
    background-size: 40px 50px;
  }

  .icon-fund-choice {
    padding-left: 50px;
    background-size: 40px 50px;
  }

 .icon-leaving-employer {
   padding-left: 50px;
   background-size: 40px 50px;
   margin-right: 0px ! important;
 }

 .banner-bottom h4 {
   width:300px;
   font-size:16px !important;
 }

  .search-result-container .panel-body {
    padding-left: 10px;
    padding-right: 10px;
  }

    ol.search-results-list {
     padding-left:20px;
    }

  .breadcrumb {
    background-color:#ffffff !important;
    color: rgb(76, 98, 108);
    font-size: 13px;
  }

  .breadcrumb li a {
    color: #4C626C;
  }

  .breadcrumb li.active {
    color: #666162 ! important;
  }

  .breadcrumb > .active {
      color: #3b3b3b;
  }

  .list-group-products, .list-group-contact-us, .list-group-latest-news, .list-group-help, .list-group-introducing-plum {
    margin-top: 10px ! important;
  }

  .list-group-products .list-group-item:first-child, .list-group-contact-us .list-group-item:first-child, .list-group-latest-news .list-group-item:first-child, .list-group-help .list-group-item:first-child, .list-group-introducing-plum .list-group-item:first-child, .list-group-fin-advice .list-group-item:first-child {
    border-top: 0px none ! important;
    border-radius: 0px ! important;
  }

  .right-col {
    margin-left:10px;
    width:300px;
 }

 .right-col-content-box {
 margin-left:10px !important;
 width:300px;
 }
  .education {
      margin-bottom:0;
    }

  .education-tabs .nav-tabs li {
    width: 100px;
  }

  .education-tabs .nav-tabs li a {
    background-size: 100px 90px;
    height: 90px;
  }

  .edu-video-thumb img {
    margin-bottom: 10px;
  }

  .edu-video-thumb p {
    text-align: left ! important;
  }

  .education-accordion-content .list-item-text {
    padding-right: 5px ! important;
    padding-left: 10px ! important;
  }

  .education-accordion-content table td {
    float: left;
    vertical-align:top;
  }

  .education-accordion-content p {
    width: 275px !important;
    margin-top: 0px ! important;
    min-height: 120px ! important;
  }

  .edu-right-col-content-box {
    margin-top: 0 !important;
  }

  .news-hub-content-box {
    width: 300px;
    margin-bottom: 10px;
  }

  .news-content {
    padding-left: 10px;
    padding-top: 10px;
  }

  .contact-us-faqs {
    padding-left: 10px;
  }

  .forms-bg-img {
     margin-bottom: 10px;
     margin-left: 10px;
    }

  .right-col-login-box {
    padding: 10px 5px;
    margin: 10px 5px 10px 10px ! important;
    width:290px !important;
  }

  .right-col-login-box h4 {
    width: 60%;
    margin-bottom: 0px;
    margin-left: 60px;
  }

.headlines-content {
    margin-bottom:10px;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
 .icon-mysuper, .icon-fund-choice, .icon-leaving-employer {
   width: 230px;
   margin-right: 10px ! important;
   vertical-align: top;
 }

 .row-footer {
   font-size: 12px ! important;
 }

 .row-footer .nav-tabs {
   float: none ! important;
 }

 .row-footer .nav-tabs li {
   display: block;
   width: auto;
 }

 .row-footer .nav-tabs li a {
   padding-bottom: 2px ! important;
   padding-left: 5px ! important;
   line-height: 10px ! important;
   border-right: 1px solid #888580 ! important;
 }

 .row-footer p {
   //text-align: left ! important;
 }

 .row-footer img {
   margin-right: 5px ! important;
   margin-bottom: 10px;
 }
}

@media (max-width: 480px) {
 body {
  font-size: 12px;
 }

  ul {
   padding-left:20px;
 }

 .navbar {
   height: auto ! important;
   font-size: 14px ! important;
 }

 .navbar-collapse {
   min-height:420px !important;
 }

  .navbar-nav li {
    width: 97%;
  }

 .navbar-nav li a {
   padding: 5px 0 ! important;
   margin: 0 20px;
   text-align: left;
   line-height:40px !important;
 }

 .navbar-nav li a#nav_home, .navbar-nav li a#nav_education {
   line-height: 40px;
 }

 .container {
   padding-right: 10px;
   padding-left: 10px;
 }

 .education {
   padding: 0px 5px ! important;
   margin-bottom: 0px;
 }

 .education-right-col {
   padding: 0px 5px ! important;
 }

 .education-accordion-content .list-item-text {
   padding-right: 5px ! important;
   padding-left: 5px ! important;
 }

 .education-accordion-content table td {
   float: left;
 }

 .education-accordion-content p {
   width: 280px;
 }

 .edu-video-thumb img {
   margin-bottom: 10px;
 }

 .img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
   margin-top: 0px;
 }

 .img-responsive .pull-left {
   margin-right: 0px;
 }

 .banner-text {
   padding: 2px 5px;
   line-height: 21px;
 }

  .banner-bottom {
    background-image:url("/content/dam/plum/images/backgrounds/xs_home-bottom_bg.png") !important;
  }

 .banner-bottom h1 {
   width: auto;
   margin: 10px;
   font-size: 22px ! important;
 }

 .banner-bottom h4 {
   width: auto;
   margin: 10px;
   font-size: 14px ! important;
   line-height: 21px;
 }

 .banner-bottom a {
   margin-left: 10px;
 }

 .home-accordion table td {
   float: left;
   margin-bottom: 10px;
   clear: both;
 }

 .xs-product-services table.table td {
   float: left;
   margin-bottom: 10px;
   clear: both;
 }

 .home-accordion .list-item-text {
   padding: 10px 5px ! important;
 }

 .icon-mysuper, .icon-fund-choice, .icon-leaving-employer {
   width: 280px;
   margin-bottom: 10px ! important;
   vertical-align: top;
 }

 .xs-marginbottom10 {
   margin-bottom: 10px;
 }

 .home-content-boxes {
   border-right: 0px none ! important;
 }

 .seminar {
   margin-bottom: 0px;
 }

 .register-now-box {
   width: 260px;
   margin: 20px 0px 0px ! important;
   text-align: center;
 }

 .market-watch {
   padding-left: 0px;
 }

 .market-watch-content {
   min-height: 100px ! important;
 }

 .market-watch .pull-left img {
   padding-right: 22px;
 }


 .plum-logo .pull-left {
   margin-right: 0px;
 }

 .plum-logo img {
   width: 80px;
 }

 .btn-login {
    margin: 0px;
    padding: 0px 10px 0px 0px;
  }

  .xs-no-padding {
      padding: 0px;
    }

  .btn-login h4 {
      background-position: 20px center;
      padding: 10px 30px 10px 35px ! important;
      margin-left: 0px ! important;
    }

  .btn-login p a {
    margin-left: 10px;
  }

 .banner-bottom {
   background-image: url("../../../../../content/dam/plum/images/backgrounds/xs_home-bottom_bg.png");
 }

 .top-navigation {
   padding: 0px;
 }

 #global_search_field {
   width: 92%;
 }

 #search-icon {
   top: -22px;
   float: right;
 }

 .search-result-container .panel-body {
   padding-left: 10px;
   padding-right: 10px;
 }

 ol.search-results-list {
   padding-left:20px;
 }

 .product-services-box {
   margin-bottom:10px;
   min-height:0px !important;
    }

 .content-title {
   margin-top:20px !important;
 }

 .seminar .content-title {
   margin-top:0 !important;
    }

 .row-footer .nav-tabs {
   margin:10px 0 20px 0;
 }

 .list-group-products, .list-group-contact-us, .list-group-latest-news, .list-group-help, .list-group-introducing-plum {
   margin-top: 10px ! important;
  }

 .list-group-products .list-group-item:first-child, .list-group-contact-us .list-group-item:first-child, .list-group-latest-news .list-group-item:first-child, .list-group-help .list-group-item:first-child, .list-group-introducing-plum .list-group-item:first-child {
   border-radius: 0px ! important;
   border-top: 0 ! important;
 }

 .breadcrumb {
   background-color: #ffffff;
 }

 .breadcrumb li a {
   color: #527d97;
 }

 .breadcrumb li.active {
   color: #666162 ! important;
 }

 .right-col img {
   margin-left:10px !important;
 }

 .right-col-content-box {
   margin-left: 0px ! important;
   margin-top:0 !important;
 }

 .news-hub-content-box {
   width:300px;
   margin-bottom:10px;
 }

 .edu-right-col-content-box {
   width:290px !important;
   margin-top:0 !important;
 }

  .education-right-col .pull-left img {
      width:290px !important;
    }

  .calc-super p {
     background-position: 10px 20px;
     background-repeat: no-repeat;
     color: #ffffff;
     padding: 10px 10px 30px 70px;
     background-size:50px 50px;
  }

  .headlines-content {
    margin-bottom:10px;
  }

  .contact-us-faqs {
    padding-left: 10px;
  }

  .forms-bg-img {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .right-col-login-box {
    margin: 10px 5px 10px 10px ! important;
    padding: 10px 5px;
  }

  .right-col-login-box h4 {
    margin-bottom: 0;
    margin-left: 60px;
    width: 60%;
  }

  .browser-content table th {
    width: 130px !important;
  }

  .browser-content table th h4 {
    margin-right:0;
    padding-left:0;
    }

  .browser-content table td {
    padding: 0px ! important;
    margin: 0px ! important;
  }

  .right-col-content-box {
    margin-left:10px !important;
  }

  .news-content {
    padding-left: 10px;
    padding-top: 10px;
  }

  .news-content h2 {
    padding-top:10px !important;
    clear:left;
  }

  .news-content img {
    width:280px;
    height:280px;
    }

}

//Stronger Super Styles

.Fit2ScreenImage div {
  margin-right:0px !important;

}


.Fit2ScreenImage img {
  width: 100%;
  height: 100%;
}

.SSGrey {
     background-color: #F9F9F9 ! important;
}

.SSBcrumb table {
     background-color: #8DA9BA;
     color: #FFFFFF;
}

.SSBcrumb table td {
     padding: 5px 5px 5px 15px;
}

.SSNav {
     background-image: url("") !important;
     background-color: @brand-primary ! important;
     border-radius: 0px;
     border-top: 5px solid #CCCCCC;
     margin-bottom: 0px;
     margin-top:0px;
     height:65px !important;
}

.SSNav li.current {
     background-image: url("") !important;
     background-color: #8DA9BA! important;
     height:60px !important;
}

.SSNav > div {
     padding: 0px;
}

.SSNav ul {
     width: 100%;
}

.SSNav ul li {
     border-right: 1px solid #FFFFFF;
     width: 25%;
     height:60px !important;

}

.SSNav ul li.last {
     border-right: 0px solid #FFFFFF;
}

.SSNav ul li a {
     color: #FFFFFF ! important;
     font-size: 18px !important;
     padding: 20px !important;
     text-align: center;
     font-weight:normal !important;
}

.SSNav ul li a:hover {
     color: #FFFFFF ! important;
}

.SSBar table {
     border-bottom: 2px solid #CCCCCC;
     width: 100%;
}

.SSContent {
     background-color: #F9F9F9;
     border-bottom: 1px solid #CCCCCC;
     margin:0px !important;
}

.SSContent2 {
     background-color: #F9F9F9;
     margin: 0px;
     padding-left:15px;
     padding-right:15px;
     padding-top: 20px ! important;
}

.list-group-SS * {
     background-color: #E9E9E9;
     border-radius: 0px ! important;
     border: 0px none ! important;
     color: #527D97;
     font-size: 14px;
}

.list-group-SS .list-group-item:first-child {
     font-weight: bold;
}

.SSContainer {
    margin-left:0px;
    margin-right:0px;
    background-color: #E9E9E9;
}

.SSContainer div {
     margin: 0px;
     padding: 0px;
}

.SShometables * table {
    height: 165px;
    width: 349px;
}

.SShometables * td {
  padding: 10px 10px 10px 110px;
  vertical-align: top;
}

.SShomeMySuper * table  {
    background-image: url("/content/dam/plumnabgsf/images/StrongerSuper/Banner_MySuper.png");
}

.SShomeSuperStream * table  {
    background-image: url("/content/dam/plumnabgsf/images/StrongerSuper/Banner_SuperStream.png");
}

.SShomeSuperStream * td {
     padding: 10px 10px 10px 100px;
}

.SShomeSMSF * table  {
    background-image: url("/content/dam/plumnabgsf/images/StrongerSuper/Banner_SMSF.png");
}
.SShomeGovernance * table  {
    background-image: url("/content/dam/plumnabgsf/images/StrongerSuper/Banner_Governance.png");
}

.SSrighttables * table {
    border:1px solid #CCC !important;
    margin-right:10px;
}

.SSrighttables * td {
  padding: 5px !important;
}

.SSTable * table {
    border:1px solid #ccc !important;
}

.SSTable * table * td {

    padding:10px !important;
}

.SSTable * table * th {
    background-color:#527497 !important;
    color:#ffffff !important;
}

.StdBanner * table {
     border: 0px none;
     margin-top: 0px;
     width: 100%;
}

.StdBanner * table td {
     border: 0px none;
     margin-top: 15px;
     padding-right: 15px;
}

.StdBanner * table td + td {
     background-color: #8DA9BA;
     color: #FFFFFF;
     margin-top: 15px;
     padding-left: 20px;
     width: 100%;
}

.StdBanner * h4 {
     color: #FFFFFF !important;
}

//End of Stronger Super Styles


//Transition Portal Styles

.home-banner h2 {
  padding:45px 20px 20px 60px;
  width:45%;
}

.home-banner a:hover {
  text-decoration:none;
  outline:none;
}

.home-banner a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

a span.style1 {
     background-image: url("/content/dam/plum/images/icons/arrow-right-blue.png");
     background-position: 186px 17px;
     background-repeat: no-repeat;
     border: 4px solid #d86018;
     font-size: 24px;
     line-height: 42px;
     padding: 10px 50px 10px 25px;
     margin-left:60px;
     color:@brand-primary !important;
}

a span.style1:hover {
     background-image: url("/content/dam/plum/images/icons/arrow-right-blue.png");
     background-position: 186px 17px;
     background-repeat: no-repeat;
     border: 4px solid #ad4924;
     padding: 10px 50px 10px 25px;
     color:#ad4924 !important;
}

.box {
  border-right:1px solid #e8a379;
  padding:10px;
}

.box:hover {
  background-color:#427d99;
}

.box h3 {
    font-size:22px;
  }

.box h3 {
  margin-top:26px !important;
  margin-bottom:23px !important;
}

.box1 {
  background-image: url("/content/dam/plum/images/icons/icon_chart.png") ;
  padding-left: 85px;
  background-repeat: no-repeat;
  background-position: 18px 20px;
  background-size: 55px 55px;
}

.box2 {
  background-image: url("/content/dam/plum/images/icons/icon_chart.png") ;
  padding-left: 85px;
  background-repeat: no-repeat;
  background-position: 18px 20px;
  background-size: 55px 55px;
}

.box3 {
  border-right:none;
  background-image: url("/content/dam/plum/images/icons/icon_chart.png") ;
  padding-left: 85px;
  background-repeat: no-repeat;
  background-position: 18px 20px;
  background-size: 55px 55px;
}

.lt-ie9 .box1, .lt-ie9 .box2, .lt-ie9 .box3 {
  padding-left:100px !important;
}

.box1 a, .box2 a, .box3 a {
  color:@navbar-default-link-color;
  outline:none;
}

.box1 a:hover, .box2 a:hover, .box3 a:hover {
  text-decoration:none;
}

.box1 a:focus,
.box2 a:focus,
.box3 a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.content-title2 {
  background-color: #8da9ba;
  margin-bottom:45px;
}

.home-content {
  margin-top:30px !important;
}

.home-content h4 {
  font-size:20px !important;
  margin-bottom:24px;
  margin-top:0 !important;
}

.home-content ul, .intro-content ul {
  padding-left:5px !important;
  margin-top:20px;
  margin-bottom:10px;
}

.list-item-text ul, .icon-arrow {
  padding-left:5px !important;
  margin-top:0 !important;
  margin-bottom:10px;
}

.home-content ul li, .list-item-text ul li, .intro-content ul li, .icon-arrow {
  background-image:url('/content/dam/plum/images/icons/arrow-list.png');
  background-repeat:no-repeat;
  background-position:0px 5px;
  padding-left:12px;
  list-style-type:none;
  padding-bottom:10px;
}

.quicklinks-box {
  padding:20px;
  margin-top:30px !important;
}

.quicklinks-box p {
  width: 90%;
  height:35px;
  color:#999999;
}

.quicklinks-box img {
  float: left;
  margin-right: 12px;
}

.quicklinks-box a {
  color:@text-color;
  outline:none;
  line-height:30px;
}

.quicklinks-box a:hover {
  color:#018086;
  text-decoration:none;
}

.quicklinks-box a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}


//End of TRansition Portal Styles

.query {
 background-color:ffff33;
 font-size:14px;color:000000;
 border-width:1px;
}

.more-info {
  background-color:#cfcdcd;
}

//newbrand additional


body {
    overflow-x: hidden;
    overflow-y: auto;
    width:100%}


.crumbcontainer {display: none;}
.market-watch .content-title {display: none}


.container-footer {text-align:left !important}


.row-footer .nav-tabs {float:none;}


//.row-footer p {text-align:left !important}

.list-group.no-bdr.list-group-contact-us {margin-top:20px!important}


// .row.row-header .bootstrap-row.parbase.multiple-columns.section {background-color:#F2F4F6} //


.wrapper.wrapper-footer {background-color:#F2F4F6;}

//.content-box ul li:before{ content:"-"; position:relative; left:-10px;}

//.content-box ul li{ text-indent:-10px; }


.content-header h1 {color:#572381; margin:15px 0 10px 0; font-size:22px!important; }

.bg-blue.content-header { background-color: #fff;}


.container-body {margin-top:10px; margin-bottom:20px;}

.container-body .product-services-box .bootstrap-row.parbase.multiple-columns.section
{background-color:#fff;}

//3columners
.content-box:nth-child(1){border-right: 0px solid #dbd9d9;
border-left: 0px solid #dbd9d9;}

.content-box:nth-child(2){border-right: 1px solid #dbd9d9;
border-left: 1px solid #dbd9d9;}

//accordion homepage

.home-accordion  {width:inherit}

.panel-heading h3 {font-size:14px !important}

.xs-product-services .panel-heading, .panel-default.forms .panel-heading {background-color:@brand-primary !important; }

.xs-product-services .panel-title a, .panel-default.forms .panel-title a {color:#fff !important}

.xs-product-services .panel-heading .panel-title a.collapsed {color:#fff !important}

.xs-product-services .panel-title a, .xs-product-services .panel-title a.collapsed,
.panel-default.forms .panel-title a, .panel-default.forms .panel-title a.collapsed
{background-image: url(/content/dam/plum/images/icons/arrows_new_wht.png); }


//top nav bar menu media querie

/*
.navbar-nav li:nth-child(8){border-right:0 solid #e4e4e4!important}

@media (max-width: 1023px) and (min-width: 768px) {
.navbar-nav li a {line-height: 40px}
}

@media (min-width:992px) and (max-width: 1023px) {

.navbar-nav li:nth-child(1), .navbar-nav li:nth-child(2), .navbar-nav li:nth-child(3),
.navbar-nav li:nth-child(4), .navbar-nav li:nth-child(5) {border-bottom:1px solid #e4e4e4!important}
.navbar-nav li:nth-child(5), .navbar-nav li:nth-child(8) {border-right:0px solid #e4e4e4!important}

}

@media (min-width:768px) and (max-width: 991px) {

.navbar-nav li:nth-child(1), .navbar-nav li:nth-child(2), .navbar-nav li:nth-child(3),
.navbar-nav li:nth-child(4){border-bottom:1px solid #e4e4e4!important}

.navbar-nav li:nth-child(4), .navbar-nav li:nth-child(8) {border-right:0px solid #e4e4e4!important}

}

@media (max-width: 767px) {
.navbar-nav {margin-left:0; margin-right:0; border-right:none !important}
.navbar li {border-right:0 !important}

.navbar li:hover {background-color:#a3adb2; }
.navbar li a:hover {color:#fff !important}
}
*/


//2 banner homepage
@media (max-width: 767px){
.market-watch {width: 100% !important; padding-left:0}
.market-watch:nth-child(1) {margin-right:3% !important}

.home-content-boxes .text-image p {width:inherit}
}

//homepage table with last bottom accordion content
@media (max-width: 767px) {
.seminar, .market-watch {margin: 10px 0 10px 0px;}
.icon-mysuper, .icon-fund-choice, .icon-leaving-employer {width: 100%;}

.home-accordion table td { width: 100%; display: block; }

.home-accordion table td p span {margin-bottom:20px}

}

@media (max-width: 1023px) and (min-width: 768px){
.list-item-text {font-size: 14px;}
}

@media (max-width: 1023px){
body {font-size: 14px;}
}

@media (max-width: 767px){
//thumbnails columns
.product-services-box { min-height:inherit !important;}
.product-services-box:nth-child(2){ border-right:0; border-left:0}
}
 //thumbnails columns
.content-box:nth-child(2) {border-right:0px solid #dbd9d9; border-left: 0px solid #dbd9d9; }

.content-box li a {
  text-decoration:none;
  font-size:14px;
  font-weight:bold;
}
.home-accordion table td a {
  font-size:14px;
  font-weight:bold;
}
.home-content-boxes .text-image a,
.home-accordion table td a {
    text-decoration: underline;
}

.home-accordion table td a:focus,
.home-content-boxes .text-image p a:focus,
.xs-product-services table td p a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

h2.panel-title a:focus {
  color: red;
  text-decoration: underline;
}

.home-content-boxes .text-image a:hover,
.home-accordion table td a:hover {
    text-decoration: none;
}

.xs-product-services .panel-heading h3, .forms .panel-heading h3 {font-size:17px !important}

.xs-product-services table td p{font-size:17px}

.content-box a:hover {text-decoration:underline !important}

.forms-publications ul li a {font-family:'CorpidBold'}

.calc-super p a {text-decoration:none}
.forms-publications ul li a:hover, .calc-super p a:hover {color:#572381!important; text-decoration:underline;}

h4  {font-family:'CorpidBold'; color:#333 !important}

.other-topics a:hover, .icon-small-video a:hover, .icon-small-forms:hover, .education-accordion-content .login a:hover, .education-accordion-content .contact a:hover {text-decoration:underline}

h2 a:hover {text-decoration:underline !important}

.list-group-latest-news {margin-top:20px !important;}

.news-hub-content h4 {font-size:17px; border-top: 1px #eaeaea solid; padding-top:20px}
.news-hub-content h4:nth-child(1) {border-top: 0px #eaeaea solid; padding-top:0px}

.news-hub-content p { padding-bottom:10px}

.content-box a {font-weight: bold}

.row-footer .nav-tabs li:nth-child(1) {padding-left:0;}

.footerbottom {padding:20px 0; margin-bottom: -20px;}


.row-footer .footerbottom  p {padding-bottom:20px; color:#7f8284; border-bottom:1px #7f8284 solid; }

.row-footer .nav-tabs li:nth-child(1) a {padding-left:0 !important;}

.bootstrap-page-home .wrapper.wrapper-body {margin-top:30px; margin-bottom:30px}

.wrapper.wrapper-footer {padding:20px 0}

@media (max-width:768px){

  .bootstrap-page-home .wrapper.wrapper-body {margin-top:20px; margin-bottom:20px}
}

//sm changes


h4, h5, h6
{
font-size: 17px;
font-weight: normal;
margin: 35px 0 20px;
}

.content-title h2
{
font-size: 24px !important;
}

.list-item-text h4 {
	font-size: 14px;
}

.edu-right-col-content-box {
    font-size: 14px;
}

.text-white {
    color: #ffffff;
}


.media h6 a {
	font-family:CorpidLight;
	font-size: 14px;
	font-weight:normal;
}

h1 b, h2 b, h3 b, h4 b, h5 b {
    font-weight: normal;
}

.abt-plum-vision img {
    padding-right: 20px;
}

.contact-us-faqs {
    font-size: 14px;
}

.btn-login h4 {
	border-radius: 3px;
}

.news-hub-content h4 a {
    font-weight: normal;
}

.news-hub-content-box {
    font-weight: bold;
}

.home-accordion table td a, .content-box li a {
    font-family: CorpidLight;
    font-weight: bold;
}

.right-col-content-box {
    font-size: 14px !important;
}

// Trigger Campaign styles added - 09/02/2016 //

.campaign-nav-tabs .nav-tabs {
  margin-top: 35px;
}

.campaign-nav-tabs .nav-tabs li a {
  border-radius: 0px;
  border: 0px none ! important;
  margin-right: 0px;
  color:#3b3b3b;
  font-weight:bold;
  padding:10px 25px;
}

.campaign-nav-tabs .nav-tabs li a:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.campaign-nav-tabs .nav-tabs li {
  background-color: #f5f7f8;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4;
  border-top: 0px solid #e4e4e4;
}

.campaign-nav-tabs .nav-tabs li.first {
  border-left: 0px none;
  border-top: 0px none;
}

.campaign-nav-tabs .nav-tabs li.active {
  border-top: 1px solid #e4e4e4;
}

.campaign-nav-tabs .nav-tabs li.first.active {
  border-left: 1px solid #e4e4e4;
}

.campaign-nav-tabs .nav-tabs li.last.active {
  border-right: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
}

.campaign-nav-tabs .nav-tabs li a:hover {
  color:#572381;
}


table.table-campaign {
  width:100%;
  margin-bottom:20px;
}

table.table-campaign tr th,
table.table-campaign tr td {
 line-height: 1.42857;
 padding: 8px;
 vertical-align: top;
}

table.table-campaign th {
  background-color: #4c626c;
  color: #ffffff;
  border-right: 2px solid #ffffff;
}

table.table-campaign td {
  background-color: #f5f7f8;
  border-bottom: 2px solid #ffffff;
}

.wrapper-plum-cta {
  background-color: #572381;
  color: #ffffff;
  margin-left: -9999em;
  margin-right: -9999em;
  padding-bottom: 25px;
  padding-left: 9999em;
  padding-right: 9999em;
  margin-bottom:-20px;
}

.wrapper-plum-cta h4 {
  color: #ffffff ! important;
}

.wrapper-plum-cta {
  a:focus {
    outline: -webkit-focus-ring-color auto 5px!important;
  }

  a,
  a:focus {
    color:#ffffff;
    text-decoration:underline;
  }
  a:hover {
    color:#ffffff;
    text-decoration:none;
  }
}

span.btn-cta {
  background-color: #c9cb00;
  border-radius: 0px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 15px;
  min-width: 155px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  &:focus {
    outline: -webkit-focus-ring-color auto 5px!important;
  }
}

.wrapper-plum-cta a span.btn-cta {
  color: #572381 ! important;
  &:focus {
    outline: -webkit-focus-ring-color auto 5px!important;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .campaign-nav-tabs .nav-tabs li a {
    padding:10px 15px;
  }

}

@media screen and (max-width: 767px) {
  .campaign-nav-tabs .nav-tabs li {
    width: 100%;
    border-left: 0;
    border-bottom: 1px solid #e4e4e4 ! important;
    margin-bottom: 0px;
  }

  .campaign-nav-tabs .nav-tabs li.active {
    border-top: 0;
  }

  .campaign-nav-tabs .nav-tabs li.first.active {
    border-top: 1px solid #e4e4e4;
    border-left:0;
  }

  .campaign-nav-tabs .nav-tabs li.last {
    border-bottom:0 !important;
  }

  .campaign-nav-tabs .nav-tabs li.last.active {
    border-right: 0;
    border-top: 0;
    border-bottom: 0 ! important; }
}

//Akamai Video styles added 28/04/2016 - Tapan Vashi //

.nab-akamai-video-section .btn-vod-play:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-play-active.png);
   width: 15px;
   height: 20px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-pause:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-pause-active.png);
   width: 15px;
   height: 17px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-stop:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-stop-active.png);
   width: 15px;
   height: 15px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-forward:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-forward-active.png);
   width: 23px;
   height: 16px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-forward:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-forward-active.png);
   width: 23px;
   height: 16px;
   background-color:transparent;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-back:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-back-active.png);
   width: 23px;
   height: 16px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-volup:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-vol-up-active.png);
   width: 25px;
   height: 19px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-voldown:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-vol-down-active.png);
   width: 17px;
   height: 19px;
   outline: -webkit-focus-ring-color auto 5px;
  }

.nab-akamai-video-section .btn-vod-transcript:focus {
   background-image:url(/content/dam/plum/images/icons/video-bt-trasncript-active.png);
   width: 25px;
   height: 21px;
   outline: -webkit-focus-ring-color auto 5px;
}


// WILL's style changes 25/7/16


img{width:auto;max-width:100%;height:auto;max-height:100%}
.media>.pull-left{padding-right:10px}
.media>.pull-right{padding-left:10px}
.bootstrap-placeholder{font-family:"Helvetica Neue",Helvetica,Arial,sans-serif!important;font-size:12px!important;font-weight:700!important;color:silver!important}
.nav-stacked .open .dropdown-menu.nav-stacked{position:static;display:block;float:none;margin-top:0;-webkit-box-shadow:none;box-shadow:none}
.nav-stacked .caret{position:absolute;top:18px;right:15px}
.col-xs-12,.col-sm-12,.col-md-12,.col-lg-12,.col-custom{float:left}
.button-subtext.button-subtext-default{font-size:11px}
.button-subtext.button-subtext-lg{font-size:14px}
.button-subtext.button-subtext-sm{font-size:9px}
.button-subtext.button-subtext-xs{font-size:9px}
.button-wrapper{display:table;text-align:left}
.button-wrapper .button-icon{display:table-cell}
.button-wrapper .button-text-wrapper{display:table-cell;white-space:normal;vertical-align:top}
.button-glyphicon.button-glyphicon-default{font-size:28px}
.button-glyphicon.button-glyphicon-lg{font-size:36px}
.button-glyphicon.button-glyphicon-sm{font-size:24px}
.button-glyphicon.button-glyphicon-xs{font-size:24px}
.comment-item{padding:10px}
#lpChat img {
    max-height: none;
}

// Homepage carousel style update - Added 19/05/2017 by Tapan Vashi

.carousel-content-list-wrapper {
  .item {
    height: 270px;
  }

  .carousel-caption {
    text-align: left;
    width: 50%;
    text-shadow: none;
    left: 24%;
    right: 0;

    h1, h2 {
      font-size: 40px!important;
      font-family: @font-family-regular;
      font-weight: 900!important;
      width: 50%;
    }

    .btn-cta {
      color: #3b3b3b;
      font-size: 18px;
      &:focus {
        outline: -webkit-focus-ring-color auto 5px!important;
      }
    }
  }
}

@media (max-width:768px){
  .carousel-content-list-wrapper {
    .carousel-caption {
	    width: 100%;
	    padding: 15px;
    }
  }
}

.wrapper-banner-cta {
  background-color: #a92582;

  .bg-secondary-blue {
    margin-bottom: 0;
  }
}

// Nav styling - Added 21/12/18 by Michael McAleer

/*
.navbar {
    margin-bottom: 7px;
}
.navbar-nav li.current:before {
    content: "";
    z-index: 0;
    display: block;
    top: 10px;
    left: 0;
    position: absolute;
    height: 50px;
    width: 100%;
    border-bottom: 5px solid #572381;
}
.navbar-nav li a {
    text-align: center;
    line-height: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size:14px !important
}

.navbar-nav>li>a:hover {
  text-decoration: underline;
  border: none;
}

@media (max-width: 767px) {
  .navbar-nav li.current:before {
    content: "";
    z-index: 0;
    display: block;
    top: 0px;
    left: 0px;
    position: absolute;
    height: inherit;
    width: 100%;
    border-left: 9px solid #572381;
    border-bottom: 0px;
  }
}

@media (min-width: 768px){
  .navbar-nav {
    float: none;
  }
}

@media (max-width: 767px){
  .navbar-nav li a {
    text-align: left;
  }
  .bg-navigation ul.navbar-nav li {
    margin-bottom: 0px!important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .bg-navigation ul.navbar-nav {
    margin-bottom: 0px;
  }
  .bg-navigation ul.navbar-nav li {
    margin-bottom: 0px!important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
	.bg-navigation ul.navbar-nav {
		margin-bottom: 0px;
	}
	.bg-navigation ul.navbar-nav li {
		margin-bottom: 0px!important;
	}
  .navbar-nav li.current:before {
    content: "";
    z-index: 0;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    height: inherit;
    width: 100%;
    border-bottom: 5px solid #572381;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
	.bg-navigation ul.navbar-nav li {
    width: 12% !important;
    border-bottom: 0px !important;
	}
	.bg-navigation ul.navbar-nav li a {
    line-height: unset !important;
	}
  .navbar-nav li.current:before {
    content: "";
    z-index: 0;
    display: block;
    top: 10px;
    left: 0;
    position: absolute;
    height: 50px;
    width: 100%;
    border-bottom: 5px solid #572381;
  }
} */

// Homepage carousel style update - Added 20/12/2018 by Michael McAleer
.carousel-content-list-wrapper {
  .item {
    height: 350px;
    .carousel-caption {
      width: 1000px;
      margin: 0 auto;
      padding-top: 0px;
      padding-bottom: 0px;
      left: 0;
      right: 0;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      h1 {
        font-size: 40px!important;
        font-family: @font-family-regular;
      }
      .list-item-text {
        width: 50%;
        p {
          font-size: 20px;
          line-height: 1.3;
        }
        .btn-cta-secondary {
          font-family: @font-family-heavy;
          &:focus {
            text-decoration: underline;
            outline: -webkit-focus-ring-color auto 5px!important;
          }
        }
      }
    }
  }
  .carousel-full-width {
    background-position: top center !important;
    background-size: cover !important;
  }
}

.carousel-inner {
  .item {
  	-webkit-transition:opacity .6s ease-in-out;
    -ms-transition:opacity .6s ease-in-out;
    -o-transition:opacity .6s ease-in-out;
  	transition:opacity .6s ease-in-out;
    opacity:0;
  }
  .active.left,
  .active.right {
  	opacity: 0;
  }
  .active,
  .next.left,
  .prev.right {
  	opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .carousel-content-list-wrapper {
    .item {
      height: 180px!important;
      padding: 0!important;
      margin: 0!important;
      overflow:hidden;
      .carousel-caption {
        position: relative;
        h1 {
          font-size: 22px!important;
        }
        .list-item-text {
          width: 50% !important;
          p {
            font-size: 15px!important;
            width: 75%;
            background-position: 46% 60%!important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .carousel-content-list-wrapper {
    .item {
      background-position: 65% 50%!important;
      height: 240px;
      padding: 0!important;
    }
  }
}

@media screen and (min-width: 1201px) {
  .carousel-content-list-wrapper {
    .carousel-caption {
      max-width: 1020px!important;
      left: 85px;
      right: 85px;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1199px) {
  .carousel-content-list-wrapper {
    .carousel-caption {
      max-width: 90%!important;
      left: 1%;
      right: 1%;
    }
  }
}

@media screen and (max-width: 767px) {
  .carousel-content-list-wrapper {
    .item:before {
      background: rgba(0,0,0,0.6);
      content: "";
      z-index: 0;
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      height: inherit;
      width: 100%;
    }
  }
}

.carousel-content-list-wrapper {
  .item {
    .carousel-caption {
      h1, h2 {
        font-weight: 400!important;
        width: 50%;
      }
      span:hover {
        text-decoration: underline;
      }
      span:focus {
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }
  .carousel-indicators {
    .active:focus {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .carousel-content-list-wrapper {
    .item {
      .carousel-caption {
        width: 100%;
        h1, h2 {
          margin-top: 0;
          width: 100%!important;
        }
        .list-item-text {
          width: 100%!important;
          .btn-cta-secondary {
            min-width: 125px!important;
            padding: 10px!important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .carousel-content-list-wrapper {
    .item {
      .carousel-caption {
        h1, h2 {
          width: 70%;
          font-size: 36px!important;
        }
      }
    }
  }
}

@media screen and (max-width: 992px){
  .carousel-content-list-wrapper {
    .item {
      overflow: hidden;
    }
  }
}

// Retirement hub - 17.12.18
.wrapper.wrapper-footer {
  background-color: #fff;
}

/* Get in touch component */
.row-footer {
  .get-in-touch {
    .col-custom {
      h2 {
        font-family: @font-family-heavy;
        font-size: 30px!important;
        font-weight: normal;
        text-align: center;
        color: @primary-purple;
      }
      .text-image {
        h3 {
          font-family: @font-family-bold;
          font-size: 18px!important;
          color: @primary-purple;
        }
        p {
          font-family: @font-family-light;
          font-size: 15px!important;
          font-weight: 200;
          line-height: 1.4!important;
          color: @primary-black;
          text-align:center;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
            color: @primary-black;
          }
          &:focus {
            outline: #3b3b3b dotted 1px!important;
            outline-offset: -2px;
          }
        }
      }
    }
  }
}

/* Icomoon glyphicons */
.icon-padlock:before {
  font-family: 'icomoon';
  content: "\e905";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-chart:before {
  font-family: 'icomoon';
  content: "\e617";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-online:before {
  font-family: 'icomoon';
  content: "\e622";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-call-us:before {
  font-family: 'icomoon';
  content: "\e907";
  font-size: 35px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-request-call-back:before {
  font-family: 'icomoon';
  content: "\e908";
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-email-us:before {
  font-family: 'icomoon';
  content: "\e62c";
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-locate:before {
  font-family: 'icomoon';
  content: "\e900";
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-news:before {
  font-family: 'icomoon';
  content: "\e60b";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-document:before {
  font-family: 'icomoon';
  content: "\e60d";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-search:before {
  font-family: 'icomoon';
  content: "\e608";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}
.icon-info:before {
  font-family: 'icomoon';
  content: "\e904";
  font-size: 50px;
  vertical-align: -webkit-baseline-middle;
  color: #572381;
}

/* stop text from breaking onto the next line */
.no-break {
  white-space: nowrap;
}

/* tel: protocol link behaviour enabled for smaller screens */
@media screen and (max-width: 767px) {
  a[href^="tel"]:link,
  a[href^="tel"]:visited,
  a[href^="tel"]:hover {
    text-decoration: underline!important;
    pointer-events: auto;
    cursor: pointer;
    color: @primary-purple!important;
  }
  .row-footer {
    .get-in-touch {
      .col-custom {
        .text-image {
          a[href^="tel"]:link span b,
          a[href^="tel"]:link span strong {
            font-size: 15px!important;
            line-height: 1.4!important;
            font-weight: 200!important;
            text-decoration: underline;
            color: @primary-purple!important;
          }
        }
      }
    }
  }
}

/* tel: protocol link behaviour disabled for larger screens */
@media screen and (min-width: 768px) {
  a[href^="tel"]:link,
  a[href^="tel"]:visited,
  a[href^="tel"]:hover {
    text-decoration: none!important;
    pointer-events: none;
    cursor: default;
    color: inherit;
  }
  /* remove focus styling for href:tel elements */
  a[href^="tel"]:focus {
    text-decoration: none!important;
    outline: none!important;
    outline-offset: none!important;
  }
}

/* Accessibility fixes */
.nav>li>a:focus {
  outline: #3b3b3b dotted 1px!important;
  outline-offset: -2px;
  text-decoration: underline;
}

.text-image {
  p {
    a img:focus {
      outline: -webkit-focus-ring-color auto 5px!important;
    }
  }
}

.btn-login p a:focus,
.btn-forgot-password p a:focus {
  outline: -webkit-focus-ring-color auto 5px!important;
}

.carousel-content-list-wrapper .item .carousel-caption a + span:focus,
.list-item-text p a + span.btn-cta-secondary:focus {
  outline: -webkit-focus-ring-color auto 5px!important;
}

.home-accordion table td a:focus,
.home-content-boxes .text-image a:focus,
.panel .list-item-text a:focus,
.content-box li a:focus,
.home-accordion table td a:focus {
  outline: #3b3b3b dotted 1px!important;
  outline-offset: -2px!important;
  text-decoration: none!important;
  color: #000;
}

.forms .panel-heading h3,
.xs-product-services .panel-heading h3 {
  a.accordion-toggle:focus {
    text-decoration: underline;
  }
}

.carousel-content-list-wrapper .carousel-indicators .active:focus,
.carousel-indicators li:focus {
  outline: -webkit-focus-ring-color auto 5px;
  background-color: @primary-magenta;
}

.carousel-caption {
  .list-item-text {
    p {
      a:focus > span {
        outline: -webkit-focus-ring-color auto 5px;
        text-decoration: underline;
      }
    }
  }
}
ol {
  .carousel-indicators {
    li {
      .active:focus {
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
  }
}
.carousel-content-list-wrapper .carousel-indicators .active:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.featured-item {
  background-color: #f2f4f6!important;
}

/* alternative heading styles */
.h1-hybrid {
  font-family: 'CorpidC1s-Regular';
  color: #572381;
  font-size: 24px;
  font-weight: 900!important;
  vertical-align: top;
}

.h2-hybrid {
  font-family: 'CorpidC1s-Heavy';
  color: #572381;
  font-size: 18px;
  font-weight: 900!important;
  vertical-align: top;
}

.h3-hybrid {
  font-family: 'CorpidC1s-Regular';
  color: #572381;
  font-size: 16px!important;
  font-weight: 900;
  vertical-align: top;
}

.h4-hybrid {
  font-family: 'CorpidC1s-Heavy';
  color: #000;
  font-size: 16px;
  font-weight: 900!important;
  vertical-align: top;
}

/* home page fixes */
.highlighted-item {
  background-color: #ffffff!important;
  .text-image {
    h2 {
      width: 50%;
    }
    p {
      font-family: @font-family-light;
      color: @primary-purple;
      font-size: 18px;
      width: 50%;
      &:last-child {
        margin-top: 10px;
      }
    }
    .btn-cta-primary {
      min-width: 120px;
      &:focus {
        text-decoration: underline;
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
    a:focus {
      text-decoration: underline;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}
.btn-cta-primary {
  &:focus {
    text-decoration: underline;
    outline: -webkit-focus-ring-color auto 5px;
  }
  &:hover {
    text-decoration: underline;
  }
}
.image-1 {
  background-image: url("/content/dam/plum/images/icons/consolidate-super.png");
  background-size: cover!important;
  height: 280px;
  background-position: top center!important;
  background-repeat: no-repeat;
}
.image-2 {
  background-image: url("/content/dam/plum/images/icons/retirement-income.png");
  background-size: cover!important;
  height: 280px;
  background-position: top center!important;
  background-repeat: no-repeat;
}

.btn-cta {
  &:focus {
    text-decoration: underline;
    outline: -webkit-focus-ring-color auto 5px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.featured-banner {
  background-image: url("/content/dam/plum/images/icons/member-benefit-banner.png");
  background-size: cover!important;
  height: 220px;
  background-position: top right!important;
  background-repeat: no-repeat;
  background-color: @primary-purple;
  color: #fff;
  h2, .h1-hybrid {
    color: #fff!important;
    font-family: @font-family-heavy;
    font-size: 40px;
    text-shadow: 1px 1px 0px #000;
    text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.7);
  }
  p {
    color: #fff!important;
    font-size: 20px!important;
    font-family: @font-family-light;
    text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.7);
    a {
      text-shadow: none;
    }
  }
}

//Styles for Value of advice pages - 10/06/20

.hero-banner-image {
  background-size: cover!important;
  height: 310px;
  background-position: top center!important;
  background-repeat: no-repeat;

  .text-overlay {
    h1 {
      font-size: 40px!important;
      font-family: 'CorpidC1s-Regular';
      font-weight: 900!important;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    p {
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      font-size: 20px!important;
    }
  }
}

.primary-button {
  border-radius: 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 15px;
  min-width: 155px;
  padding: 10px 20px;
  text-align: center;
  color: #fff!important;
  background-color: #572381;
  text-decoration: none !important;

  &:hover {
    background-color: #a92582!important;
    text-decoration: underline!important;
    border: 1px solid #a92582;
  }

  &:focus {
    background-color: #a92582!important;
    text-decoration: underline!important;
    border: 1px solid #a92582;
  }
}

.financial-workbook-intro {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 50px;
}

.text-corpid-bold {
  font-family: @font-family-bold;
}

.banner-text-purple {
  h1, h2, h3 {
    color: @brand-primary;
  }
}

.voa-list {
  ul {
    padding-left: 0;

    li {
      list-style-type: none;
      background-image: url('/content/dam/plum/images/Campaigns/financial-workbook/icon-tick-plum.png');
      background-size: 22px;
      background-repeat: no-repeat;
      padding-left: 32px;
      background-position: 0px 3px;
      padding-bottom: 10px;
    }
  }
}

    @media screen and (max-width: 992px) {
        .hero-banner-image {
            background-position: 65% !important;

            &:before {
              background: rgba(0,0,0,.6);
              content: "";
              z-index: 0;
              display: block;
              top: 0;
              left: 0;
              position: absolute;
              height: inherit;
              width: 100%;
            }

            .text-overlay {
              h1, p {
                color: #ffffff !important;
              }
            }
        }

        .bg-image-75 {
          background-position: 75% !important;
        }
    }

    @media screen and (max-width: 767px) {
      .banner-text-purple {
        h1, h2, h3 {
          color: @white;
        }
      }

      .financial-workbook-intro {
        padding-bottom: 20px;
      }

      .voa-landing-intro br {
        display: none;
      }
    }

/* Plum campaign styles - added 02/07/2021 */

.border {
   border: 1px solid #e4e4e4;
}
.border-top {
   border-top: 1px solid #e4e4e4;
}
.border-right {
   border-right: 1px solid #e4e4e4;
}
.border-bottom {
   border-bottom: 1px solid #e4e4e4;
}
.border-left {
   border-left: 1px solid #e4e4e4;
}
.border-primary {
    border-color: #572381!important;
}
.border-secondary {
    border-color: #a92582!important;
}
.border-dark {
    border-color: #ccc!important;
}
.border-black {
    border-color: #000!important;
}
.border-white {
    border-color: #fff!important;
}

.plum-campaign .lighter-hero-image {
    -webkit-background-size: cover!important;
    background-size: cover!important;
    height: 590px;
    background-position: top center!important;
    background-repeat: no-repeat;
}
.plum-campaign .lighter-hero-image .text-overlay h1 {
    color: #fff!important;
    font-size: 40px!important;
    font-family: 'CorpidC1s-Regular';
    font-weight: 900!important;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
}
.plum-campaign .lighter-hero-image .text-overlay p {
    color: #fff!important;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    font-size: 20px!important;
}
.plum-campaign .row-footer .get-in-touch .col-custom .text-image p {
    text-align: left;
}
.plum-campaign h2 {
    font-size: 32px!important;
    font-family: 'CorpidC1s-Regular';
}
.plum-campaign h3 {
    font-size: 28px!important;
    font-family: 'CorpidC1s-Regular';
}
.plum-campaign p {
    font-size: 15px!important;
}
@media screen and (max-width: 767px) {
    .plum-campaign .lighter-hero-image {
        -webkit-background-size: cover!important;
        background-size: cover!important;
        height: 320px;
        background-position: top center!important;
        background-repeat: no-repeat;
        padding-top: 0px!important;
    }
    .plum-campaign .lighter-hero-image:before {
        background: rgba(0,0,0,.6);
        content: "";
        z-index: 0;
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        height: inherit;
        width: 100%;
    }
    .plum-campaign .lighter-hero-image {
        background-position: 58% 80%!important;
    }
    .plum-campaign .lighter-hero-image .text-overlay h1 {
        color: #fff!important;
        font-size: 25px!important;
        font-weight: normal!important;
    }
    .plum-campaign .lighter-hero-image .text-overlay p {
        color: #fff!important;
        font-size: 20px!important;
    }
}
@media screen and (min-width: 993px) {
    .plum-campaign .lighter-hero-image .text-overlay h1 {
        color: #572381!important;
        font-size: 40px!important;
        font-family: 'CorpidC1s-Regular';
        font-weight: 900!important;
        text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    }
    .plum-campaign .lighter-hero-image .text-overlay p {
        color: #000!important;
        text-shadow: 1px 1px 1px rgba(0,0,0,.1);
        font-size: 20px!important;
    }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
    .plum-campaign .lighter-hero-image .text-overlay h1 {
        color: #572381!important;
    }
    .plum-campaign .lighter-hero-image .text-overlay p {
        color: #000!important;
    }
}

.vcenter-item{
    display: flex;
    align-items: center;
}

@import "akamai-video.less";
@import "retirement-hub.less";
@import "retirement-income.less";
@import "plum-spacing.less";
@import "plum-retirement-hub.less";
@import "mysuper-tabs.less";
@import "fontawesome.less";
@import "transition-portal.less";
@import "sticky-nav.less";
@import "updated-plum-styles.less";
@import "tabs.less";
@import "megamenu.less";
@import "fee-repricing.less";
