.wrapper-sticky-nav {
    background-color: @brand-primary;
    border-bottom: 1px solid rgba(255,255,255,0.6);

    #mlcStickyNav {
        padding: 0;
        height: 100% !important;
        margin-bottom: 0;
        margin-top: 0;
        .nav-item {
            .nav-link {
                font-size: 17px !important;
                font-family: @font-family-regular;
                font-weight:  normal;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    
    #mlcStickyNav {
        .navbar-brand {
            display: none;
        }
    
        .navbar-nav {
            height: 105px;
            margin-top: 0;
        }
    
        .nav-item {
            text-align: center;
            position: relative;
            border-bottom: 5px solid transparent;
            border-right: 0 !important;
            height: 100% !important;
            min-width: 160px;
    
            .nav-link {
                width: auto;
                height: 105px;
                padding: 0 !important;
                padding-top: 35px !important;
                color: @white !important;
                text-decoration: none;
                top: 0;
                transform: none;
                margin-bottom: 1px;
    
                &:hover,
                &:focus {
                    color: #fab226 !important;
                    background-color: transparent;
                }
            }
    
            &.active,
            &:hover,
            &:focus {
                border-bottom: 5px solid #fab226 !important;
                color: #fab226 !important;
                background-color: transparent;
            }
    
            &.active {
                .nav-link {
                    color: #fab226 !important;
                    background-color: transparent;
                }
            }
    
            &:hover.chevron-down:before,
            &:hover.chevron-up:before {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .wrapper-sticky-nav {
        #mlcStickyNav {
            margin-left: -15px;
            margin-right: -15px;

            .nav-item {
                min-width: 155px !important;
                width: 155px !important;
            }

            .nav-link {
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .wrapper-sticky-nav {
        .container {
            padding: 0;
            max-width: 100%;
        }
    }

    .sticky-nav-margin-top {
        margin-top: 100px;
    }

    #mlcStickyNav {
        position: relative;
        background-size: unset;
        background-color: transparent !important;

        .navbar-header {
            height: 75px;
            text-align: center;
            border-bottom: 1px solid rgba(255,255,255,0.6);
        }

        .navbar-brand {
            padding: 28px 0 0;
            margin-right: 0;
            width: 100%;
            color: @white;
            font-size: 17px;
            font-family: @font-family-bold;
            display: inline-block;
            float: none;
            height: 75px;
        }

        .navbar-toggle {
            background-color: transparent;
            position: absolute;
            top: 12px;
            right: 0;
            margin-right: 15px;
            border: 0;

            .navbar-toggle-icon {
                display: none;
            }

            &:before {
                content:"\f078";
                font-family: "Font Awesome Pro Light";
                font-size: 17px;
                color: @white; 
            }

            &[aria-expanded="true"]:before {
                content:"\f077";
                font-family: "Font Awesome Pro Light";
                font-size: 17px;
                color: @white; 
            }
        }

        .navbar-nav {
            background-color: @brand-primary;
            width: 100%;
            margin: 0;
        }

        #mlcStickyNavContent {
            min-height: 1px !important;
            border-color: transparent;
            border: 0;
            box-shadow: none;
        }

        .nav-item {
            width: 100%;

            &.chevron-down:before,
            &.chevron-up:before {
                display: none;
            }

            &:hover {
                background-color: transparent;
            }

            &.active,
            &.active:focus,
            &:focus {
                .nav-link {
                    color: #fab226 !important;
                    background-color: @brand-primary;
                    font-family: @font-family-heavy;
                    border-bottom: 6px solid #fab226;
                }
            }

            .nav-link {
                width: 100%;
                text-align: center;
                color: @white !important;
                margin: 0;
                text-decoration: none;
                height: 50px;
                border-bottom: 1px solid rgba(255,255,255,0.6);
            }
        }

        .nav-item:last-child {
            .nav-link {
                border-bottom: 0;
            }
        }

        .nav-item:last-child.active,
        .nav-item:last-child.active:focus {
            .nav-link {
                border-bottom: 6px solid #fab226;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .wrapper-sticky-nav {
        #mlcStickyNav {
            margin-left: -15px;
            margin-right: -15px;

            .navbar-header {
                float: none;
            }

            .navbar-toggle {
                display: inline-block;
            }

            .nav-item {
                border: 0 !important;
                border-top: 1px solid rgba(255,255,255,0.6) !important;
                width: 100% !important;
            }
        }

        #mlcStickyNav .navbar-collapse.collapse {
            display: none !important;
          }
          
          #mlcStickyNav .navbar-collapse.collapse.in {
            display: block !important;
          }
    }
}
