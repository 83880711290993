.page-wrapper-mysuper h1 {
    padding: 30px 0 0;
    font-size: 24px !important;
    color: #572381;
}
.page-wrapper-mysuper h2 {
    color: #572381
}
.page-wrapper-mysuper .h2-lg h2 {
    font-size: 22px !important;
}
.wrapper-fullwidth-ribbon {
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #F2F4F6;
  padding-right: 9999em;
  margin-right: -9999em;
  padding-left: 9999em;
  margin-left: -9999em;
}
.mysuper-tab-wrapper .nav-tabs>li>a {
  padding: 196px 0px 0px;
  background-color: #fff !important;
  background-position: center top !important;
  font-size: 24px;
  font-family: CorpidC1s-Bold;
  display: inline-block;
  width: 100%;
  height: 351px;
  vertical-align: middle;
  background-position: center bottom !important;
  background-repeat: no-repeat;
  border: 0px;
  text-align: center;
  color: #3B3B3B;
}
.mysuper-tab-wrapper .nav-tabs>li.active>a,
.mysuper-tab-wrapper .nav-tabs>li>a:hover,
.mysuper-tab-wrapper .nav-tabs>li.active>a:hover {
    border: 0px;
}
.mysuper-tab-wrapper .nav-tabs>li.active>a,
.mysuper-tab-wrapper .nav-tabs>li.active>a:hover {
    color: #572381;
}
.mysuper-tab-wrapper .nav-tabs>li>a:focus,
.mysuper-tab-wrapper .nav-tabs>li.active>a:focus {
    text-decoration: none;
}
.mysuper-tab-wrapper .nav-tabs {
    height: auto;
    margin-bottom: 0px;
}
.mysuper-tab-wrapper .nav-tabs>li {
    width: 33%;
    border: 0px;
}
.tab-pane.mysuper {
  background-color: #fff;
}
.tab-pane.mysuper ul.media-list>li {
    padding-left: 0px;
}
.mysuper-tab-wrapper .nav-tabs {
    margin-bottom: 0px;
    position: relative;
    top: 1px;
    border-bottom: 0px;
}

.mysuper-tab-wrapper .tab-pane .list-item-text>p {
  margin-bottom: 0px
}
.mysuper-tab-wrapper .tab-pane .list-item-text>p .panel-title {
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  display: block;
  padding: 30px;
  font-size: inherit;
}
.mysuper-tab-wrapper .tab-pane table {
    color: #3B3B3B;
}
.page-wrapper-mysuper .table-striped>tbody>tr:nth-child(odd) {
    background-color: #F2F4F6;
}
.mysuper-tab-wrapper .tab-pane table caption {
    color: #572381;
    font-size: 17px;
    font-family: CorpidBold;
    padding: 30px 30px 0px;
}
.mysuper-tab-wrapper .tab-pane table tr td {
    padding: 30px;
}
.mysuper-tab-wrapper .tab-pane table,
.mysuper-tab-wrapper .tab-pane table caption {
  background-color: #F2F4F6;
}
.page-wrapper-mysuper table tr th {
    background-color: #572381;
    color:#fff;
}
.page-wrapper-mysuper table tr th, 
.page-wrapper-mysuper table tr td{
    padding: 15px
}
.mysuper-tab-wrapper .nav-tabs>li.active>a {
  background-position: center 0px !important;
}
.mysuper-tab-wrapper .list-item-text ul li {
    list-style-type: disc;
}
@media screen and (max-width: 992px){
  .mysuper-tab-wrapper .nav-tabs>li>a {
    padding: 200px 0px 0px;
    font-size: 26px;
    height: 351px;
  }
}
@media screen and (max-width: 767px){
  .mysuper-tab-wrapper .tab-pane table tr td {
    width: 100%;
    display: block;
  }
  .mysuper-tab-wrapper .tab-pane table tr td:not(:last-child) {
    padding-bottom: 0px
  }
  .mysuper-tab-wrapper .nav-tabs>li>a {
    padding: 109px 0px 0px;
    font-size: 20px;
    height: 197px;
    background-size: 428px !important;
  }
}
